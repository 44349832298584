//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, useEffect, useRef, FC, MouseEvent, useContext, CSSProperties, forwardRef, HTMLProps } from "react"
import { Editor } from '@tinymce/tinymce-react'
import { Editor as TinyMCEEditor } from 'tinymce'
import TextareaAutosize from 'react-textarea-autosize'
import { Icon, NavLink, Popout, SmartCount, SmartDiv, SmartImage, SmartInput, SmartSelect, useEffect2, useEventListener } from "./misc"
import { canEdit, colorNumber, divideFloat, inputStyle, keyboardSwitchedText, leftCut, multipliers, numberColor, parseDate, parsedSeconds, propsDocSource, ReadText, redirect, testAttribs, testid, UtcDate } from "./globals"
import { DataTypes, FieldTypes, FileActions, Intervals, MachshevetClient, PickListItem, SettingGroup, Units } from "./Declarations"
import { MiniCal } from './MiniCal'
import { ControlProps2, MainContext } from "./styles"

//export const PdfPick: FC<ControlProps2> = props => {
//    const [pdfUrl, setPdfUrl] = useState<string | null>(null);

//    useEffect(() => {
//        if (props.Value) {
//            // Decode the Base64 string and create a Blob
//            const binaryString = atob(props.Value); // Decoding Base64
//            const len = binaryString.length;
//            const bytes = new Uint8Array(len);
//            for (let i = 0; i < len; ++i) {
//                bytes[i] = binaryString.charCodeAt(i);
//            }
//            const blob = new Blob([bytes], { type: 'application/pdf' });

//            // Create a URL for the Blob
//            const url = URL.createObjectURL(blob);
//            setPdfUrl(url);

//            // Cleanup the URL when the component unmounts
//            return () => URL.revokeObjectURL(url);
//        }
//        return;
//    }, [props.Value]);

//    if (!pdfUrl) {
//        return <PdfPickOld {...props} />
//    }

//    return (
//        <div style={{ height: '100vh', width: '100%' }}>
//            <iframe
//                src={pdfUrl}
//                style={{ width: '100%', height: '100%', border: 'none' }}
//                title="PDF Viewer"
//            />
//        </div>
//    );
//};

export const PdfPick: FC<ControlProps2> = props => {
    const [counter, setCounter] = useState(0)
    const [val, setVal] = useState(props.Value)
    //const [fileData, setFileData] = useState<Uint8Array>()

    useEffect(() => {
        setCounter(prev => prev + 1)
    }, [props.Pages])
    const propSrc = propsDocSource(props)
    //let src = propSrc !== undefined ? propSrc + '&z=' + counter : props.Value//just to refresh
    let src = propSrc + '&z=' + counter //just to refresh
    if (val) {
        //const base64 = Buffer.from(props.Value, "binary").toString("base64")
        //src = `data:application/pdf;base64,${base64}`;
        //const b64 = atob(props.Value)
        //const b64 = btoa(val)
        //src = `data:application/pdf;base64,${b64}`



        //const binaryString = window.atob(val); // Comment this if not using base64
        //const bytes = new Uint8Array(binaryString.length);
        //const dat = bytes.map((byte, i) => binaryString.charCodeAt(i));
        //setFileData(dat)

        //var blob = new Blob([val], { type: 'application/pdf' });
        //src = URL.createObjectURL(blob);
        //URL.revokeObjectURL(src);
    }

    //const toshow = props.recordID && !props.recordType?.endsWith("Input") ? true : false
    const toshow = props.recordID || val
    return <div style={{ flexGrow: 1 }}>{canEdit(props) && <div>
        <input {...testAttribs(props)} type="file" onChange={e => {
            if (e.currentTarget.files && e.currentTarget.files[0]) {
                const file = e.currentTarget.files[0]
                props.onChange!(file)
            }
        }} accept="application/pdf" />
        <Icon name="Download" onClick={() => window.location.href = propsDocSource(props, undefined, FileActions.Download)!} /></div>}
        {toshow && <embed id="TestPdfEmbed" src={src} style={{ width: "100%", height: 400 }} />}
        {/*{toshow && fileData && <Document  file={{ data: fileData }} />}*/}
    </div>
}

export const ImagePick: FC<ControlProps2> = props => {
    const src = propsDocSource(props)
    return canEdit(props) ?
        <div id="TestImageContainer">
            <input {...testAttribs(props)} type="file" style={{ display: 'block' }} onChange={async e => {
                const targ = e.currentTarget
                if (targ.files && targ.files[0]) {
                    const file = targ.files[0]
                    await props.onChange!(file)
                }
            }} accept="image/*" />
            <img src={src} style={{ maxWidth: 700 }} />
        </div>
        : <SmartImage src={src || ""} />
}

export const TifPick: FC<ControlProps2> = props => {
    const [page, setPage] = useState(1)
    const paglist = Array.from(Array(props.Pages).keys())
    const src = propsDocSource(props, page)
    return <div><div style={{ display: "flex", gap: 5, justifyContent: "center" }}>{paglist.map(x => <span key={x} style={{ border: "var(--primary)", borderWidth: 1, borderStyle: "solid", padding: 3, cursor: "pointer", fontWeight: x + 1 === page ? 900 : "unset" }} onClick={() => setPage(x + 1)}>{x + 1}</span>)}</div><img src={src} /></div >
}

export const AutoCompletePick: FC<ControlProps2> = props => {
    const [results, setResults] = useState<PickListItem[]>([])
    const [open, setOpen] = useState(false)
    const aborter = useRef(new AbortController())
    async function reload(term: string) {
        aborter.current.abort()
        aborter.current = new AbortController()
        const gp = props.gridProps!
        gp.Term = term
        const arr = await MachshevetClient.GetMemberOptions(gp)
        setResults(arr!)
    }

    return <div style={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
        <BasePick {...props}
            onBlur={() => setTimeout(() => setOpen(false), 1000)}
            onFocus={async () => {
                setOpen(true)
                await reload(props.Value)
            }} onChange={async v => {
                if (props.Key !== "AddressSearch.") {
                    await props.onChange!(v)
                }
                setOpen(true)
                await reload(v)
            }} />
        {open &&
            <Popout id="Test_Popout"
                style={{ overflowY: "auto", borderColor: 'gray', borderStyle: 'solid', borderWidth: 1, borderRadius: 5, zIndex: 1, backgroundColor: "white" }}>
                {results.map(x => <SmartDiv key={x.Key} onClick={async () => {
                    await props.onChange!(x.Key)
                    setOpen(false)
                }}>{x.Value}</SmartDiv>)}
            </Popout>
        }
    </div>
}

export const FilePick: FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    const vref = React.useRef<HTMLVideoElement>(null)
    const [video, setVideo] = useState(false)
    const prp2 = { ...props }
    prp2.memberData = { ...prp2.memberData!, Editable: false }
    const elm = () => {
        switch (props.FileType) {
            case "pdf":
                return <PdfPick {...prp2} />
            case "csv":
            case "doc":
            case "txt":
                //maxheight for csv files in importdata commandinput
                return <div style={{ maxHeight: "8vh", overflowY: "auto" }}>{props.FileText}</div>
            case "tif":
                return <TifPick {...prp2} />
            case "docx":
            case "htm":
            case "html":
            case "svg":
                return <>
                    <Icon name="Print" onClick={() => {
                        const hsrc = propsDocSource(props)
                        const newWindow = window.open(hsrc, '_blank');
                        newWindow!.onload = () => {
                            newWindow!.print()
                        }
                    }} />
                    <div dangerouslySetInnerHTML={{ __html: props.FileHtml! }} />
                </>
            default: return <>
                {props.showTools && <Icon name="Print" onClick={() => {
                    ctx.printHtml!("<html><body><div><figure> <img src=\"data:image/*;base64," + props.Value + "\"style={width:\"100%\";}/></figure></div></body></html>")
                }} />}

                <img src={propsDocSource(props)} style={{ maxWidth: "100%" }} />
            </>
        }
    }
    let accepttypes = ""
    if (props.memberData.FileTypes) accepttypes = props.memberData.FileTypes.map(x => "." + x).join(",")
    return <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        {canEdit(props) && <div style={{ display: "flex" }}>
            <SmartInput {...testAttribs(props)} type="file" style={{ minWidth: "10vw" }} accept={accepttypes} onClick={e => e.currentTarget.value = ""} onChange={async e => {
                const trg = e.currentTarget
                if (trg.files && trg.files[0]) {
                    const file = trg.files[0]
                    await props.onChange!(file)
                }
            }} /><Icon name="StartCamera" onClick={async () => {
                setVideo(true)
                const strm = await navigator.mediaDevices.getUserMedia({ video: true })
                const vid = vref.current!
                vid.srcObject = strm
                await vid.play()

            }} />
            <Icon name="Clear" onClick={async () => {
                await props.onChange!(null)
            }} />
            <Icon name="Download" onClick={() => window.location.href = propsDocSource(props, undefined, FileActions.Download)!} />
        </div>}
        {video && <video ref={vref} />}
        {elm()}
    </div>
}

export const PhonePick: FC<ControlProps2> = props => {
    const p2: ControlProps2 = { ...props }
    const stl: React.CSSProperties = { ...p2.style }
    stl.direction = "ltr"
    p2.style = stl
    return <div style={{ display: "flex", alignItems: "center", gap: "1vh", flexGrow: 1 }}>
        {props.memberData!.Editable ? <BasePick  {...p2} onClean={e => {
            e = e.replace("*", "-")
            if (e === "-") return ""
            e = e.replace(/[^\d-]/g, '')//leaves only digis and hyphens
            e = e.replace(/(?!^-)-/g, '')//removes nonfirst hyphens
            return e
        }} /> : <a href={"tel:" + props.Value} style={{ direction: "ltr", textDecoration: "none" }}>
            <DisplayBox field={props} />
        </a>}
        {props.Value && <Icon name='Dial' onClick={async e => {
            e.stopPropagation()
            await MachshevetClient.Dial(props.Value as number)
        }} />}
    </div>
}

export const EmailPick: FC<ControlProps2> = props => {
    return canEdit(props) ? <BasePick  {...props} /> : <a href={"mailto:" + props.Value} style={{ display: "inline-block", flexGrow: 1, textDecoration: "none" }} ><DisplayBox field={props} /></a>
}

export const NumberPick: FC<ControlProps2> = props => {
    return <BasePick {...props} onClean={v => {
        if (v.length > 18) return v//hack for longs, so ts doesnt ruin precision, might need less then 18
        return v === "" ? v : +v
    }} valueTip={props.Value} />
}

export const MoneyPick: FC<ControlProps2> = props => {
    return <BasePick {...props} onClean={v => {
        v = leftCut(v, 'Fr.')
        v = v.replace(/[^\d.-]/g, '')
        if (v === "-") return ""
        if (v === "") return ""
        return +v
    }} valueTip={props.Value} />
}

export const BasePick = forwardRef<HTMLInputElement, ControlProps2 & { list?: string, onClean?: (v: string) => any, onClick?: React.MouseEventHandler<HTMLInputElement>, onFocus?: React.FocusEventHandler<HTMLInputElement> }>((props, ref) => {
    const ctx = useContext(MainContext)
    const [editFlag, setEditFlag] = useState(false)
    const [rawText, setRawText] = useState(props.Text)
    const stl: CSSProperties = { ...props.style }
    let valbind = editFlag ? rawText : props.Text || (props.Value === undefined ? undefined : '' + props.Value)
    valbind = valbind || ""//null makes problems

    useEffect(() => {//needed mainly for datepick, where value is changed not via userinput (but rather via minical click)
        if (!editFlag) {
            let newval = props.Value
            if (newval instanceof Date) newval = newval.toLocaleString()
            setRawText(newval)
        }

    }, [props.Value])
    stl.whiteSpace = props.memberData?.WordWrap ? "break-spaces" : "pre"



    return canEdit(props) ? <SmartInput {...testAttribs(props)} ref={ref} value={valbind} style={stl} onContextMenu={e => e.stopPropagation()} list={props.list} title={props.valueTip} draggable
        onDragStart={e => e.preventDefault()}
        onChange={async e => {
            setEditFlag(true)
            const rawVal: any = e.currentTarget.value
            setRawText(rawVal)
            let cleanVal = rawVal
            if (props.onClean) cleanVal = props.onClean(cleanVal)//without this line typing - in moneypicks throws err
            await props.onChange!(cleanVal)
        }}
        onBlur={e => {
            let vl = e.currentTarget.value
            if (editFlag) {
                ctx.devLog!("end edit", vl)
                setEditFlag(false)
            }
            if (props.onClean) vl = props.onClean(vl)//without this line, phonepick doesnt work in editmode in datagrid
            props.onBlur && props.onBlur(vl)
        }}
        onDoubleClick={e => e.stopPropagation()}
        onClick={props.onClick}
        onFocus={props.onFocus} />
        : <DisplayBox field={props} />
})
BasePick.displayName = "BasePick"

export const DisplayBox: FC<HTMLProps<HTMLDivElement> & { field: ControlProps2 }> = props => {
    const ctx = useContext(MainContext)
    const fld = props.field
    const instyle = { ...fld.style }
    const md = fld.memberData
    if (!instyle.whiteSpace) instyle.whiteSpace = md.WordWrap ? "break-spaces" : "pre"
    instyle.fontSize = md.FontSize || props.style?.fontSize
    if (md.FontSizePercent) instyle.fontSize = (md.FontSizePercent * 100) + "%"
    instyle.userSelect = "all"
    instyle.display = "block"
    if (md.ForeColor) instyle.color = numberColor(md.ForeColor)
    const outstyle = { ...props.style }
    outstyle.padding = 0
    outstyle.flexGrow = 1
    if (md.FieldType === FieldTypes.Percent && fld.Value) {
        instyle.backgroundColor = numberColor(ctx.data.SecondaryColor)
        instyle.width = (fld.Value * 100) + '%'
    }
    const vStr = fld.Text || (fld.Value === undefined ? null : fld.Value) || ""
    const str = '' + vStr
    const trm = fld.gridProps?.Term
    const switched = trm && keyboardSwitchedText(trm)

    let terms = [trm, switched, md.FilterText, md.FilterText && keyboardSwitchedText(md.FilterText)].filter(x => x).map(x => x!.toLowerCase())
    if (trm?.startsWith("@")) terms = trm.substring(1).split(" ")

    const parts = str.split(new RegExp(`(${terms.join("|")})`, 'gi'))

    let hilited = parts.map((part, index) => {
        return terms.includes(part.toLowerCase()) ? <span key={index} style={{ backgroundColor: 'yellow' }}>{part}</span> : part
    })

    if (!trm && md.DataType === DataTypes.Number && ![FieldTypes.Percent, FieldTypes.Phone, FieldTypes.Measure].includes(md.FieldType!)) hilited = [<SmartCount key={1} number={fld.Value} fieldType={md.FieldType} letBold={false} coinSymbol={fld.Coin} />]

    const forntyp = md.ForeignTypeName || fld.RefType
    const uselink = forntyp && fld.gridProps?.SettingGroup !== SettingGroup.PickColumns

    return <div {...props} style={outstyle}  >
        <div {...testAttribs(fld)} title={fld.valueTip} data-displayname={md?.LocalName} style={instyle}>
            {uselink ? <NavLink recordType={forntyp} recordID={fld.RefID || (fld.Value ? +fld.Value : undefined)} > {hilited}</NavLink> : hilited}
        </div>
    </div>
}

export const MeasurePick: React.FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    const [unit, setUnit] = useState((props.memberData.MeasureUnit && canEdit(props)) ? props.memberData.PickList.find(x => x.Value === ctx.localized(Units[props.memberData.MeasureUnit!])) : (props.memberData.PickList ? props.memberData!.PickList[0] : undefined))
    const [editFlag, setEditFlag] = useState(false)
    const [dispValue, setDispValue] = useState('')
    const [value, setValue] = useState(props.Value)

    function refreshUnit() {
        if (value) {
            const descSorted = props.memberData!.PickList.sortBy(x => -x.Key)
            const u = descSorted.find(x => value / x.Key >= 1)
            setUnit(u)
            const vl = +value
            const ky = +u?.Key
            const endval = divideFloat(vl, ky)
            setValue(endval)
            setDispValue(endval.toString())
        }
    }
    useEffect(() => {
        if (canEdit(props)) {
            if (props.memberData.PickList) refreshUnit()
            if (!value && !unit && props.memberData) {
                const lst = props.memberData.PickList.sortBy(x => x.Key)
                let newunt = lst[0]
                if (props.memberData.MeasureUnit) {
                    const untnam = Units[props.memberData.MeasureUnit]
                    const trnslt = ctx.localized(untnam.toString())
                    newunt = props.memberData.PickList.find(x => x.Value === trnslt)!
                }
                setUnit(newunt)
            }
        }
    }, [])

    return canEdit(props) ? <div style={{ display: "flex" }}>
        <SmartInput {...testAttribs(props)} type='number' style={{ ...props.style }} value={editFlag ? dispValue : value}
            onChange={e => {
                setEditFlag(true)
                const inpt = e.currentTarget.value
                setDispValue(inpt)
                //setValue(+inpt)
                setValue(inpt)
                const val = +inpt * (unit?.Key)
                props.onChange!(val)
            }}
            onBlur={() => setEditFlag(false)} />
        <SmartSelect value={unit?.Value} style={props.style} onChange={e => {
            const u = props.memberData!.PickList.find(x => x.Value === e.currentTarget.value)!
            setUnit(u)
            props.onChange!(value * u.Key);
        }}>
            {props.memberData!.PickList && props.memberData!.PickList.sort((a, b) => a.Key - b.Key).map(x => <option key={x.Key} value={x.Value}>{x.Value}</option>)}
        </SmartSelect>
    </div> : <DisplayBox field={props} />
}

export const PercentPick: FC<ControlProps2> = props => {
    function divideByHundred(value: string) {
        const v = value.replace(/[^\d.-]/g, '')
        const val = v.split(".")
        let wholeval = val[0]
        const decimalValue = val[1]

        if (wholeval.startsWith("-")) wholeval = '-00' + wholeval.substring(1, wholeval.length)
        else wholeval = '00' + wholeval

        const sStart = wholeval ? wholeval.substring(0, wholeval.length - 2) : '';
        const sEnd = wholeval ? wholeval.substring(wholeval.length - 2, wholeval.length) : '';
        const st2 = (sStart ? sStart : '') + '.' + (sEnd ? sEnd : '') + (decimalValue ? decimalValue : '');
        const val2 = parseFloat(st2);
        return val2;
    }
    return <BasePick {...props} onClean={v => divideByHundred(v)} valueTip={props.Value} />
}

export const ColorPick: React.FC<ControlProps2> = props => {
    const hex = props.Value ? numberColor(+props.Value) : ''//that plus is cuz sometimes it comes in as string
    return canEdit(props) ?
        <div style={{ display: 'flex', flexGrow: 1 }}>
            <input type="color" value={hex} onChange={e => props.onChange!(colorNumber(e.currentTarget.value))} style={{ flexGrow: 1 }} />
            <BasePick {...props} />
            <Icon name="Clear" onClick={async () => await props.onChange!(null)} />
        </div>
        :
        <div style={{ backgroundColor: numberColor(props.Value) }}>{props.Value}</div>
}

export const SpanPick: FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    const [interval, setInterval] = useState<Intervals>()
    const [num, setNum] = useState<number>()
    useEffect(() => {
        if (canEdit(props) && props.Value) {
            const val = parsedSeconds(props.Value)
            setInterval(val.Key)
            setNum(val.Value)
        }
    }, [props.Value])

    return canEdit(props) ? <div style={{ display: 'flex', flexGrow: 1 }}>
        <SmartInput {...testAttribs(props)} type='number' style={props.style} value={num || ""} onChange={e => {
            const value = e.currentTarget.valueAsNumber
            setNum(value)
        }} onBlur={() => {
            if (interval && num) {
                const mlt = multipliers()[interval]
                const newval = num * mlt
                props.onChange!(newval)
            }
        }} />
        <SmartSelect value={interval} id="TestIntervals" onChange={async e => {
            const intv = +e.currentTarget.value as Intervals
            setInterval(intv)
            const mlt = multipliers()[intv]
            const newval = num! * mlt
            props.onChange!(newval)
        }} style={props.style}>
            <option>{ctx.localized('Choose') + '…'}</option>
            {Object.keys(Intervals).filter(x => !isNaN(+x)).map(x => <option id={"TestIntervals" + "_" + x} value={x} key={x} >{ctx.localized(Intervals[+x] + 's')}</option>)}
        </SmartSelect>
    </div> : <DisplayBox field={props} />
}

export const UrlPick: React.FC<ControlProps2 & { template?: string }> = props => {
    let str = props.Value ? props.Value as string : '';
    if (props.template) str = props.template.replace("{Handle}", props.Value)
    return canEdit(props) ?
        <div style={{ display: "flex" }}>
            <BasePick {...props} />
            <a href={str} style={{ display: 'flex' }}><Icon name="Navigate" /></a>
        </div>
        :
        <a href={str} style={{ display: "block" }}>{str}</a>
}

export const DatePick: FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    const [opened, setOpened] = useState(false)

    useEffect(() => {
        setOpened(false)
    }, [props.Value])


    let dat = props.Value && typeof props.Value == "string" ? parseDate(props.Value) : undefined
    if (props.Value instanceof Date) dat = props.Value
    const returntext = (num: number, interval: Intervals) => { return (Math.round(num * 100) / 100) + ' ' + ctx.localized(Intervals[interval] + 's') }
    const dt = new Date(props.Value)
    const curtim = new Date().getTime()
    const valtim = dt.getTime()
    const diff = Math.abs(valtim - curtim)
    let ttip = ""
    if (diff < 1000) ttip = returntext(diff, Intervals.Millisecond)
    else if (diff < 1000 * 60) ttip = returntext(diff / 1000, Intervals.Second)
    else if (diff < 1000 * 60 * 60) ttip = returntext(diff / 1000 / 60, Intervals.Minute)
    else if (diff < 1000 * 60 * 60 * 24) ttip = returntext(diff / 1000 / 60 / 60, Intervals.Hour)
    else if (diff < 1000 * 60 * 60 * 24 * 7) ttip = returntext(diff / 1000 / 60 / 60 / 24, Intervals.Day)
    else if (diff < 1000 * 60 * 60 * 24 * 30.4167) ttip = returntext(diff / 1000 / 60 / 60 / 24 / 7, Intervals.Week)
    else if (diff < 1000 * 60 * 60 * 24 * 30.4167 * 12) ttip = returntext(diff / 1000 / 60 / 60 / 24 / 30.4167, Intervals.Month)
    else ttip = returntext(diff / 1000 / 60 / 60 / 24 / 30.4167 / 12, Intervals.Year)
    return canEdit(props) ?
        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}  >
            <BasePick {...props} onClick={() => setOpened(!opened)} onClean={v => {
                const dt = parseDate(v, ctx.data.DateEditFormat!, ctx)
                if (!dt) return ""
                const ret = dt.toISOString()
                return ret
            }} />
            {opened && <Popout tabIndex={-1} style={{ display: "flex", fontSize: "90%", borderWidth: 1, borderStyle: "solid", borderRadius: 3, zIndex: 2, backgroundColor: "white" }} >
                <MiniCal selectedDate={dat} showTime={(props.memberData!.ShowTime || true) && (props.memberData!.DatePrecision == undefined || props.memberData!.DatePrecision <= 3)} onSelected={e => {
                    const dt = e.toISOString()
                    props.onChange!(dt)
                    setOpened(false)
                }} />
            </Popout>}
        </div >
        : <DisplayBox field={{ ...props, valueTip: ttip }} />
}

export const BitPick: FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    const val: boolean | null = props.Value
    if (canEdit(props)) {
        if (props.memberData.Required)
            return <div {...testAttribs(props)} title={props.memberData.LocalName} style={{ backgroundColor: val ? numberColor(ctx.data.PrimaryColor) : "lightgray", borderRadius: 10, display: "flex", justifyContent: val ? "end" : "start", minWidth: 30, flexGrow: 1 }} onClick={async () => {
                const vl = !val
                await props.onChange!(vl)
                props.onBlur && props.onBlur(vl)//so it works in datagrid>editmode
            }}>
                <div style={{ height: 20, width: 15, borderRadius: 10, backgroundColor: "white", boxShadow: "0 0.1em 0.3em rgba(0,0,0,0.3)" }} />
            </div>
        else {
            const p2 = { ...props }
            p2.memberData.PickList = []
            p2.memberData.PickList.push({ Key: true, Value: ctx.localized("Yes"), Icon: "Yes", ForeColor: 65280 })
            p2.memberData.PickList.push({ Key: false, Value: ctx.localized("No"), Icon: "No", ForeColor: 16711680 })
            return <SelectPlus {...testAttribs(props)} testName={props.memberData.Name} value={props.Value} options={p2.memberData.PickList} onChange={e => props.onChange!(e)} style={{ flexGrow: 1 }} />
        }
    }
    else {
        const pr2 = { ...props }
        pr2.Text = val ? "✓" : "X"
        if (val === null || val === undefined) pr2.Text = ""
        pr2.style!.color = numberColor(val ? 32768 : 16711680)
        return <DisplayBox field={pr2} />
    }
}

export const AudioPick: FC<ControlProps2> = props => {
    const src = propsDocSource(props)
    return <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
            <input type="file" id={testid(props)} onChange={async e => {
                if (e.currentTarget.files && e.currentTarget.files[0]) {
                    const file = e.currentTarget.files[0]
                    await props.onChange!(file)
                }
            }} accept="audio/*" />
            <Icon name="Download" onClick={() => window.location.href = propsDocSource(props, undefined, FileActions.Open)!} />
        </div>
        <audio controls preload="auto">
            <source src={src} type="audio/wav" />
        </audio>
    </div>
}

export const TextPick: FC<ControlProps2> = props => {
    return <BasePick {...props} />
}

export const LongTextPick: FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    const dval = props.Value ? props.Value.toString() : ''
    const stl = inputStyle(props.style, ctx)
    const { height, ...stl2 } = stl
    return <TextareaAutosize {...testAttribs(props)} value={dval} style={stl2}
        onChange={async e =>
            await props.onChange!(e.currentTarget.value, undefined, undefined, undefined)
        }
        onBlur={e => {
            props.onBlur && props.onBlur(e.currentTarget.value)
        }}
    />
}

export const ExpiryPick: React.FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    return canEdit(props) ? <BasePick {...props} onClean={e => {
        const match = e.match(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/)
        if (match) {
            const month = +match[1] - 1
            const year = '20' + match[2]
            const dt = UtcDate(new Date(+year, month, 1))
            ctx.devLog!("date" + dt, dt)
            return dt
        }
        return undefined
    }} /> : <DisplayBox field={props} />
}

export const HtmlPick: FC<ControlProps2 & { isEditable: boolean }> = props => {
    const ctx = useContext(MainContext)
    const injectedHtmlRef = useRef(null)
    const [isEditable, setIsEditable] = useState(props.isEditable)

    const setEditable = () => {
        if (canEdit(props)) setIsEditable(true)
    }

    useEffect(() => {
        if (injectedHtmlRef.current) {
            (injectedHtmlRef.current as HTMLElement).addEventListener('dblclick', (e) => {
                const t = e.target as HTMLElement
                const rcid = t.dataset.recordid
                const rctyp = t.dataset.recordtype
                if (rcid && rctyp) {
                    redirect(undefined, rctyp, +rcid, false)
                }
                // console.log(rcid, rctyp);
            })
        }
    }, [])

    function setupHandler(editr: TinyMCEEditor) {
        editr.on('SetContent', () => {
            const ifr = editr.iframeElement
            ifr?.setAttribute('id', testid(props) + '_Html' + rand)
            ifr?.setAttribute('data-required', (props.memberData.Required || false).toString())
            ifr?.setAttribute('data-value', props.Value)
            ifr?.setAttribute('data-fieldtype', props.memberData.FieldType ? props.memberData.FieldType.toString() : '')

        })
        editr.ui.registry.addButton('ClearMargin', {
            text: ctx.localized("ClearMargin"),
            onAction: function () {
                const body = editr.getBody()
                const pEls = body.querySelectorAll('p')
                pEls.forEach(p => {
                    if (p.style.marginBlock !== '0px') {
                        p.style.marginBlock = '0'
                        p.style.marginBottom = '0'
                        p.setAttribute('data-mce-style', 'margin-block: 0px;margin-bottom: 0px')
                    } else {
                        p.style.marginBlock = '16px'
                        p.style.marginBottom = '16px'
                        p.setAttribute('data-mce-style', 'margin-block: 16px;margin-bottom: 16px')
                    }
                })

                editr.save()
            }
        })

        editr.ui.registry.addMenuButton('fields', {
            text: ctx.localized("DataFields"),
            fetch: function (callback: any) {
                const items = props.memberData!.PickList ? props.memberData!.PickList.sortBy(x => x.Value).map(x => {
                    return {
                        type: 'menuitem',
                        text: x.Value,
                        onAction: function () {
                            editr.insertContent("{" + x.Key + "}");
                        }
                    };
                }) : [];
                callback(items);
            }
        });
    }

    const rand = Date.now().toString() + Math.random().toString(16).slice(2); //to show 2 html pickers in the same page - as when report is open in edit and in popup
    return isEditable ?
        <Editor value={props.Value as string || ''} data-value={props.Value} data-fieldtype={props.memberData.FieldType} apiKey="lxi00e9pxi95iuxlk5byy1l5jaq7a6jxx3a6fib0miqtqlkd" onEditorChange={e => props.onChange!(e)} plugins={'code'}
            init={{
                menubar: 'edit view format tools',
                plugins: ['directionality', 'pagebreak', 'link', 'table'],
                toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ltr rtl | pagebreak | fields | ClearMargin | link | table',
                setup: setupHandler,
                relative_urls: false,
                remove_script_host: false,
                pagebreak_separator: '<div style="page-break-before: always"></div>',
                end_container_on_empty_block: true,
                ui_mode: 'split', //to show the menus in dialog - if doesnt work, try the next hack. nnp. 2/5/23
                contextmenu: 'table ltr rtl'
            }} />
        :
        <div>
            {canEdit(props) ? <Icon name="EditHtml" onClick={setEditable} /> : <></>}
            <span dangerouslySetInnerHTML={{ __html: props.Value }} ref={injectedHtmlRef} />
        </div>

}

export const FormatPick: FC<ControlProps2> = props => {
    return canEdit(props) ? <div style={{ display: "flex" }}>
        <BasePick  {...props} />
        <select style={{ ...props.style, width: "100%" }} onChange={e => props.onChange!(e.currentTarget.value)}>{props.memberData!.PickList?.map(x => <option value={x.Key} key={x.Key}>{x.Key + '  --  ' + x.Value} </option>)}</select>
    </div> : <DisplayBox field={props} />
}

export const MergePick: FC<ControlProps2> = props => {
    const ref = useRef<HTMLInputElement>(null)
    return canEdit(props) ? <div style={{ display: "flex" }}>
        <BasePick  {...props} ref={ref} />
        <SelectPlus options={props.memberData.PickList} onChange={e => {
            const inp = ref.current
            if (!inp) return
            const startPos = inp.selectionStart || 0
            const endPos = inp.selectionEnd || 0
            const curval = inp.value
            const newValue = curval.substring(0, startPos) + '{' + e + '}' + curval.substring(endPos, curval.length)
            props.onChange!(newValue)
        }} />
    </div> : <DisplayBox field={props} />
}

export const PathPick: FC<ControlProps2> = props => {
    const [sign, setSign] = useState(props.Value)
    const [mouseDown, setMouseDown] = useState(false)

    function isTouchEvent(e: MouseEvent) { return e.type.match(/^touch/); }

    function getCoords(e: MouseEvent) {
        if (isTouchEvent(e)) {
            var t = e as unknown as TouchEvent
            return t.targetTouches[0].clientX + ',' + t.targetTouches[0].clientY
        }
        const parentOffset = e.currentTarget.getBoundingClientRect()
        return (e.pageX - parentOffset.left) + ',' + (e.pageY - parentOffset.top)
    }

    function handleMouseDown(e: MouseEvent) {
        setMouseDown(true);
        const coords = getCoords(e);
        const newsign = (sign || "") + 'M' + coords + ' ';
        setSign(newsign);
    }

    function handleMouseMove(e: MouseEvent) {
        if (mouseDown) {
            const coords = getCoords(e);
            const newsign = (sign || "") + 'L' + coords + ' ';
            setSign(newsign);
        }
    }

    async function handleMouseUp() {
        if (mouseDown) {
            setMouseDown(false)
            await props.onChange!(sign)
        }
    }

    return props.memberData!.Editable ? < div style={{ display: "flex" }}>
        <svg style={{ color: "transparent", height: "50px", width: "300px" }} fill="White" >
            <rect style={{ color: "transparent", width: "100%", height: "50px" }} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove} onMouseLeave={handleMouseUp} />
            <path stroke="navy" strokeWidth="2" fill="none" pointerEvents="none" d={sign} />
        </svg>
        <Icon name="Clear" onClick={async () => {
            setSign(null)
            await props.onChange!(null)

        }} />
    </div>
        : <svg style={{ color: "transparent", height: "50px", width: "300px" }}  >
            <path stroke="navy" strokeWidth="2" fill="none" pointerEvents="none" d={sign} />
        </svg>
}

export const SvgPick: FC<ControlProps2> = props => {
    return <div>
        {canEdit(props) && <div style={{ display: "flex", flexDirection: "column" }}>
            <input type="file" onChange={async e => {
                const trg = e.currentTarget
                if (trg.files && trg.files[0]) {
                    const file = trg.files[0]
                    //ReadText(file).then(x => props.onChange!(x))
                    const newval = await ReadText(file)
                    props.onChange!(newval)
                }
            }} accept="image/svg+xml" />
            <LongTextPick {...props} />
        </div>
        }
        <span dangerouslySetInnerHTML={{ __html: props.Value! as string }} />
    </div>
}

export const PickListControl: FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    const [options, setOptions] = useState(props.memberData.PickList)

    let opts = options || []
    if (!opts.length) {
        opts = opts.concat({ Key: props.Value, Value: props.Text || "" })
    }

    useEffect2(async () => {
        if (props.Value && options) {
            if (!options.some(x => x.Key === props.Value)) await refresh()
        }
    }, [props.Value])

    async function refresh() {
        //if ((props.recordType || props.mainCommand) && canEdit(props)) {//in column>daterange filter there is not recordtype and should not be refreshed
        //if (props.recordType && canEdit(props)) {//in column>daterange filter there is not recordtype and should not be refreshed.button in sureportinput there is no recordtype

        const gp = props.gridProps
        if (gp && canEdit(props)) {//in column>daterange filter there is not recordtype and should not be refreshed.button in sureportinput there is no recordtype
            //if (props.commandInputGetter) gp.CommandValues = props.commandInputGetter()
            const res = await MachshevetClient.GetMemberOptions(gp)
            setOptions(res)
        }
    }
    let val = props.Value
    if (val === undefined) val = ""
    // if (props.ForeignID) val = props.ForeignID //this made bug in placememnt shiftid, changing in ui did not show on scren

    const md = props.memberData
    const usenew = md.HasIcons || opts.some(x => x.Icon)

    const pr2 = { ...props }
    pr2.memberData.PickList = opts

    return canEdit(props) ? <>
        {!usenew && <SmartSelect {...testAttribs(props)} value={val} style={{ ...props.style, flexGrow: 1 }} onMouseDown={async () => refresh()} onChange={async e => {
            let val: unknown = e.currentTarget.value
            const num = Number(val)
            if (num || val === "0") val = num
            await props.onChange!(val)
            if (props.onBlur) props.onBlur(val)
        }}>
            <option value="" id={"Test" + md.Name + "_"}>{ctx.localized("Choose") + '...'}</option>
            {opts.map(x => <option id={"Test" + md.Name + "_" + x.Key} value={x.Key} key={x.Key}>{x.Value}</option>)}
        </SmartSelect>}
        {usenew && <SelectPlus  {...testAttribs(props)} options={opts} value={props.Value} required={md.Required} testName={md.Name} title={md.LocalName} style={{ ...props.style, flexGrow: 1 }} onChange={v => props.onChange!(v)} onMouseDown={async () => refresh()} />}
    </> : <DisplayBox field={props} />
}

export const PickListNode: FC<PickListItem & { testname?: string, onClick?: React.MouseEventHandler<HTMLDivElement> }> = props => {
    return <SmartDiv id={"Test" + props.testname + "_" + props.Key} style={{ alignItems: "center", cursor: "pointer", display: "flex", gap: "1ch", color: numberColor(props.ForeColor), fill: numberColor(props.ForeColor) }} onClick={props.onClick}>
        {props.Icon && < Icon name={props.Icon} />}
        <div style={{ whiteSpace: "nowrap" }}> {props.Value}</div>
    </SmartDiv>
}

export const SelectPlus: FC<Omit<HTMLProps<HTMLDivElement>, "onChange"> & { options: Record<string, any>[], onChange?: (v: any) => void, required?: boolean, testName?: string, keyField?: string, valueField?: string, iconField?: string }> = props => {
    const ctx = useContext(MainContext)
    const [isOpen, setIsOpen] = useState(false)
    const [term, setTerm] = useState<string>()
    const [focused, setFocused] = useState(false)
    const refSearch = useRef<HTMLInputElement | null>(null)
    const refContainer = useRef<HTMLDivElement | null>(null)

    useEventListener("mousedown", e => {
        if (!refContainer.current!.contains(e.target as Node)) {
            setIsOpen(false)
        }
    })


    let opts = props.options.map(x => {
        const pli = new PickListItem()
        pli.Key = x[props.keyField || "Key"]
        pli.Value = x[props.valueField || "Value"]
        pli.Icon = x[props.iconField || "Icon"]
        pli.ForeColor = x["ForeColor"]
        return pli
    })
    if (props.required !== true) {
        const pli: PickListItem = { Key: undefined, Value: "-" }
        opts = [pli].concat(opts)
    }
    const selopt = opts.find(x => x.Key === props.value)!
    useEffect(() => {
        if (isOpen && refSearch.current) refSearch.current.focus()
    }, [isOpen])


    if (term) {
        const swtch = keyboardSwitchedText(term)
        opts = opts.filter(x => [x.Key + '', x.Value!].some(y => [term, swtch].some(z => y.toLowerCase().includes(z))))

    }
    const { options, ...props2 } = props
    //no flexgrow here. could be sent from parent if necessary
    return <div {...props2} ref={refContainer} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} style={inputStyle({ ...props.style, display: "flex" }, ctx, focused)}>
        <div onClick={e => {
            e.stopPropagation()
            setIsOpen(!isOpen)
            setFocused(!isOpen)
        }} style={{ ...props.style, display: "flex", justifyContent: "space-between", flexGrow: 1, padding: 0 }} >
            <PickListNode {...selopt} />
            <span style={{ fontWeight: "bold", transform: "scaleY(0.5)", alignItems: "center", display: "flex" }}>V</span>
        </div>
        {/*{id = { "Test_Field_" + props.memberData.Name + "_PickListPopout" }}*/}
        {isOpen && <Popout style={{ ...props.style, overflowY: "auto", display: "flex", flexDirection: "column", zIndex: 1 }}>
            {props.options.length > 4 && <input ref={refSearch} placeholder="⌕" value={term} onChange={e => {
                e.stopPropagation()
                setTerm(e.currentTarget.value)
            }} style={{ backgroundColor: "white" }} />}
            <div id={"Test_Field_" + props.testName + "_PickListPopout"} style={{ display: "flex", flexDirection: "column", overflowY: "auto" }}>{opts.map(x => (
                //testname = { props.memberData.Name } 
                <PickListNode {...x} key={x.Key} testname={props.testName} onClick={() => {
                    if (props.onChange) props.onChange!(x.Key)
                    setIsOpen(false)
                    setFocused(false)
                }} />
            ))}</div>
        </Popout>
        }
    </div>

}

export const XmlPick: FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)
    const [changeTime, setChangeTime] = useState(Date.now() - 10000)
    const [firstLoad, setFirstLoad] = useState(true)

    useEffect(() => {
        if (!firstLoad) setChangeTime(Date.now())
        else setFirstLoad(false)
    }, [props.Value])

    const edtbl = props.memberData.Editable && !props.memberData.Locked
    const arr = new Array<{ tagName: string, content?: string, indent: number, open?: boolean, close?: boolean }>()
    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(props.Text || " ", 'text/xml')
    Array.from(xmlDoc.children).forEach(child => appendChiled(child, 0))
    function appendChiled(node: Element, indent: number) {
        if (node.children.length) {
            const open = { tagName: node.tagName, indent: indent, open: true }
            arr.push(open)

            Array.from(node.children).forEach(nd => appendChiled(nd, indent + 1))

            const close = { tagName: node.tagName, indent: indent, close: true }
            arr.push(close)
        }
        else {
            const chld = { tagName: node.tagName, content: node.textContent || "", indent: indent }
            arr.push(chld)
        }
    }
    function getSpaces(indent: number) {
        const tab = '       '
        let padding = ''
        for (let i = 0; i < indent; ++i)
            padding += tab
        return padding
    }

    const cp2 = { ...props }
    const datediff = (Date.now() - changeTime) / 1000
    const instyle: CSSProperties = { userSelect: "all" }
    instyle.whiteSpace = cp2.memberData.WordWrap ? "break-spaces" : "pre"
    instyle.fontSize = cp2.memberData.FontSize || props.style?.fontSize
    const outstyle = { ...props.style }
    outstyle.direction = "ltr"

    if (props.showChanges !== false && props.recordKey !== "0" && !props.memberData?.Editable && (datediff < 9)) instyle.backgroundColor = numberColor(ctx.data.SecondaryColor)
    const color = cp2.memberData?.ForeColor

    return !edtbl ? <div style={outstyle}>
        <div {...testAttribs(props)} title={props.valueTip} data-displayname={props.memberData?.LocalName} style={{ ...instyle, color: numberColor(color) || props.style?.color }}>{
            arr.map(elm => (
                elm.open ?
                    <span key={Date.now.toString()}>{getSpaces(elm.indent) + '<' + elm.tagName + '>\n'}</span>
                    :
                    elm.close ?
                        <span key={Date.now.toString()}>{getSpaces(elm.indent) + '</' + elm.tagName + '>\n'}</span>
                        :
                        <span key={Date.now.toString()}>{getSpaces(elm.indent) + '<' + elm.tagName + '>'}<b>{elm.content}</b>{'</' + elm.tagName + '>\n'}</span>
            ))
        }</div>
    </div>
        : <BasePick {...props} />
}

export const FontPick: FC<ControlProps2> = props => {
    const [fonts, setFonts] = useState<string[]>([])
    useEffect(() => {
        const fontCheck = new Set([
            'Arial', 'Helvetica', 'Times New Roman', 'Courier', 'Verdana', 'Georgia',
            'Palatino', 'Garamond', 'Bookman', 'Comic Sans MS', 'Trebuchet MS',
            'Arial Black', 'Impact', 'Segoe UI'
        ])
        const detected: string[] = []

        for (const font of fontCheck.values()) {
            if (document.fonts.check(`12px "${font}"`)) {
                detected.push(font)
            }
        }
        setFonts(detected)
        //setSelectedFont(detected[0] || 'sans-serif');
    }, [])

    return canEdit(props) ? <select value={props.Value} onChange={e => props.onChange!(e.target.value)}
        style={{ fontFamily: props.Value }} > {fonts.map(font => (
            <option key={font} value={font} style={{ fontFamily: font }}>
                {font}
            </option>
        ))}
    </select> : <DisplayBox field={props} />
}

export const JsonPick: FC<ControlProps2> = props => {
    const prsd = props.Value && JSON.parse(props.Value)
    const js = JSON.stringify(prsd, null, 2)
    return props.editPage && !canEdit(props) ? <pre style={{ ...props.style, direction: "ltr" }}> {js}</pre > : <BasePick {...props} />
}