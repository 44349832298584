//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, FC, useEffect, useRef, useContext, HTMLProps, ReactElement, ReactNode, CSSProperties, forwardRef, MouseEventHandler } from 'react'
import { ColumnChooser } from './chooseColumns'
import { Control } from './Control'
import { Link } from 'react-router-dom'
import { HtmlPick, FilePick } from './pickers'
import { alertStore, AppContext, ColumnData2, CommandFile2, ContextMenuProps, ControlProps2, doCommand, MainContext, MenuProps, prioritizeCommands, TableBodyProps } from './styles'
import { AppData, Attachment, ChartPoint, ColumnData, CommandMessage, ControlProps, DashboardData, DashboardTile, FieldTypes, GridProps, Intervals, LiteRecordData, LoginResult, MachshevetClient, PickListItem, RecordsData, ReportTypes, SettingGroup } from "./Declarations"
import { defaultGridProps, KeyValuePair, numberColor, getClientUrl, redirect, TabType, GetFileBytes, showConfirm, getControlProps2, findScrollableParent, testAttribs, adjustColor, inputStyle, getGlobalUrl, docSource, RecordFormProps, filteredColumns, parsedSeconds, controlRecord2, formattedNumber, getControlProps3, goToRecord } from "./globals"
import { RecordForm } from './RecordForm'
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'
import L from 'leaflet'

Array.prototype.options = function (this: Array<Record<any, any>>) {
    const ret = this.map(x => <option id={"Test" + x.Key} value={x.Key} key={x.Key} > {x.Value} </option>)
    return ret
}

export function useDebuggableState<T>(initialState?: T): [T | undefined, (newState: T | ((prevState: T | undefined) => T)) => void] {
    const [state, setState] = useState<T | undefined>(initialState)
    const teststring = "Term"
    const logmode = 0
    const proxySetState = (newState: T | ((prevState: T | undefined) => T)) => {
        if (typeof newState === 'function') {
            const updater = newState as (prevState: T | undefined) => T
            setState(prevState => {
                const updatedState = updater(prevState)
                if (prevState && updatedState) {
                    Object.keys(updatedState).forEach(key => {
                        const prevValue = prevState[key as keyof T]
                        const newValue = updatedState[key as keyof T]
                        if (prevValue !== newValue) {
                            if (key === teststring) {
                                var a = 1
                            }
                            if (logmode) {
                                console.log(`Property "${key}" changed from`, prevValue, 'to', newValue)
                                console.trace(); // Logs the exact stack trace for the change
                            }
                        }
                    });
                }
                return updatedState
            });
        } else {
            if (state && newState) {
                Object.keys(newState).forEach(key => {
                    const prevValue = state[key as keyof T]
                    const newValue = newState[key as keyof T]
                    if (prevValue !== newValue) {
                        if (key === teststring) {
                            var a = 1
                        }
                        if (logmode) {
                            console.log(`Property "${key}" changed from`, prevValue, 'to', newValue);
                            console.trace(); // Logs the exact stack trace for the change
                        }
                    }
                });
            }
            setState(newState);
        }
    };
    return [state, proxySetState];
}

export function useInterval(callback: () => void, delay: number, waitTillDone = false) {
    const savedCallback = useRef(() => { })
    const isRunning = useRef(false)

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        const tick = async () => {
            if (!waitTillDone || !isRunning.current) {
                if (!waitTillDone) {
                    savedCallback.current()
                } else {
                    if (!isRunning.current) {
                        isRunning.current = true
                        try {
                            await savedCallback.current()
                        }
                        catch { }
                        isRunning.current = false
                    }
                }
            }
        }
        const id = setInterval(tick, delay)
        return () => clearInterval(id)
    }, [delay, waitTillDone])
}

export function useEventListener(event: string, handler: EventListener) {
    useEffect(() => {
        document.addEventListener(event, handler)
        return () => {
            document.removeEventListener(event, handler)
        };
    }, [event, handler])
}

export function useEffect2(effect: () => void, deps?: React.DependencyList) {
    useEffect(() => {
        effect()
    }, deps)
}

export const Popover: FC<HTMLProps<HTMLDivElement> & { focused: boolean }> = props => {
    return <div {...props} popover="auto" />
}

export const ProgressBar: FC<{ percent: number, title?: string }> = props => {
    const ctx = useContext(MainContext)!
    const prcnt = props.percent * 100
    return <div style={{ backgroundColor: 'white', borderWidth: 2, borderStyle: 'solid', borderRadius: 5, borderColor: numberColor(ctx.data.PrimaryColor) }} title={props.title}>
        <div style={{ width: prcnt + '%', backgroundColor: numberColor(ctx.data.PrimaryColor), borderRadius: 5 }}>
            <SmartCount number={props.percent} fieldType={FieldTypes.Percent} />
        </div>
    </div>
}

export const Attention: FC<{ record: LiteRecordData, data: RecordsData }> = props => {
    const ctx = useContext(MainContext)!
    const [state, setState] = useState(true)
    const flds = getControlProps2(props.record.Fields, props.data.Columns)
    if (!state) return <span />
    return <SmartDiv style={{ borderColor: 'gray', borderStyle: 'solid', borderWidth: 1, borderRadius: 5, backgroundColor: 'white' }} onClick={() => {
        const rd = new RecordsData()
        rd.RecordType = props.data.RecordType
        rd.Records = [{ ...props.record, Fields: [] }]

        const rfp: RecordFormProps = {}
        rfp.record = rd

        ctx.openRecord!(rfp)
    }}>
        <div style={{ display: 'flex', backgroundColor: numberColor(ctx.data.PrimaryColor), color: 'white', padding: 5, gap: "1vh", justifyContent: 'space-between', fill: "white" }}>
            <Icon name={props.data.RecordType!} />
            {props.record.RecordName}
            <div style={{ display: "flex" }}>
                <ColumnChooser Group={SettingGroup.Popup} RecordType={props.data.RecordType} ReportID={props.data.ReportID} />
                <Icon name="Hide" onClick={e => {
                    e.stopPropagation()
                    setState(false)
                }} />
            </div>
        </div>
        <div style={{ padding: 3, fontSize: "90%", overflow: 'hidden', borderRadius: 5 }}>
            {flds.filter(x => x.Value || x.memberData!.SubReportID).map(y => <div key={y.memberData.Name} style={{ display: 'flex', gap: 3 }}>
                <Icon name={y.memberData!.Icon!} title={y.memberData!.LocalName} />
                <Control field={{ ...y, showTools: false, recordID: props.record.RecordID, recordType: props.record.RecordType }} />
            </div>)}
        </div>
    </SmartDiv>
}

export const Preview: FC<{ recordType: string, id: number, miniMode: boolean }> = props => {
    const [state, setState] = useState<RecordsData>()

    async function reload() {
        const pr = await MachshevetClient.Preview(props.recordType, props.id)
        setState(pr)
    }

    useEffect2(async () => {
        await reload()
    }, [props.id])


    const rec = state?.Records[0]
    let flds = rec && getControlProps3(rec, state.Columns)
    if (props.miniMode) flds = flds?.filter(x => x.Value && !x.memberData!.ListType)
    const gp = new GridProps()
    if (flds) gp.RecordValues = controlRecord2(flds, props.id)
    gp.ParentRecordType = props.recordType

    return <div id="TestPreviewContainer" style={{ display: "flex", flexDirection: "column", gap: "1ch", overflowX: 'auto' }}>
        {!props.miniMode && <div style={{ backgroundColor: "#f5f5f5", borderColor: "#DDD", padding: "1.5ch", borderWidth: 1, display: "flex" }}>
            <span style={{ fontWeight: "bold", flexGrow: 1 }}>{rec?.RecordName} </span>
            <ColumnChooser Group={SettingGroup.PreviewFields} RecordType={props.recordType} onChange={reload} />
        </div>
        }
        <div id="TestPreview" style={{ overflowY: 'auto', display: 'flex', flexWrap: 'wrap', gap: "3ch", flexGrow: 1, alignContent: 'start' }}> {flds && flds.map(x => {
            const cp2 = x
            cp2.recordID = props.id
            cp2.showChanges = false
            cp2.style = { fontWeight: 'bold' }
            cp2.showTools = false
            cp2.memberData.WordWrap = true
            const gp2 = { ...gp }
            gp2.ReportID = x.memberData.SubReportID
            cp2.gridProps = gp2
            return <div key={x.Key}>
                <span style={{ fontSize: "80%" }}>{x.memberData!.LocalName}</span>
                <Control field={{ ...cp2 }} />
            </div>
        })}</div>
        {state && !props.miniMode && <QuickAdds {...state} />}
    </div>
}

export const QuickAdds: FC<RecordsData> = props => {
    const app = useContext(AppContext)!;
    const ctx = useContext(MainContext)!;
    return <div id="TestQuickAdds" style={{ display: "flex", justifyContent: 'space-around', fontSize: "120%" }}>
        {props.QuickAdds.map(x => <SmartDiv key={x.Key} title={ctx.localized('Add') + ' ' + ctx.localized(x.Key) + ' (' + ctx.localized(x.Value) + ')'} onClick={async () => {
            const cp = new ColumnData2()
            cp.Name = 'Add'
            cp.commandParams = [x.Key + '.' + x.Value]
            const gp = defaultGridProps(props.Records[0].RecordID)
            gp.RecordType = props.RecordType
            await doCommand(cp, 0, gp, app, ctx)
        }} >
            <Icon name={x.Key} />
            <span style={{ fontWeight: "bold" }}>+</span>
        </SmartDiv>
        )}
    </div>
}

export const EmailDropDown: FC<ControlProps2 & { Selected: { Key: string, Value: string }[] }> = props => {
    const [options, setOptions] = useState<KeyValuePair<string, string>[]>([])
    const [selected, setSelected] = useState<{ Key: string, Value: string }[]>([])
    const [inputValue, setInputValue] = useState("")
    const [opened, setOpened] = useState(false)

    useEffect(() => {
        if (props.Selected) {
            props.Selected.map(x => {
                setSelected(prev => [...prev, x])
            })
        }
    }, [])

    useEffect2(async () => {
        if (props.onChange) await props.onChange(selected)
    }, [selected])

    function addEmail(text: string) {
        const emailPerson = text.split(',')
        const newarr = selected.concat({ Key: emailPerson[0], Value: emailPerson.length > 1 ? emailPerson[1] : "" })
        setSelected(newarr)
    }
    function removeEmail(address: string) {
        setSelected(prev => [...prev.filter(x => x.Key != address)]);
    }

    return <div style={{ display: "flex", gap: 2, flexWrap: 'wrap' }}>
        {selected.map((email, ind) =>
            <div style={{
                display: "flex", background: "#e8e6e6", border: "2px solid gray", borderRadius: "5px", padding: "0 3px", gap: "10px"
            }} key={ind}>
                <i style={{ cursor: "pointer" }} onClick={x => removeEmail(email.Key)}>x</i>
                <div style={{ display: "grid", lineHeight: "100%", whiteSpace: "nowrap", fontSize: "0.8em" }}>
                    <div style={{ fontWeight: "bold" }}>{email.Value}</div>
                    <div>{email.Key}</div>
                </div>
            </div>)}
        <div onMouseLeave={() => setOpened(false)}>
            <SmartInput value={inputValue} id="TestRecipients" onChange={async e => {
                const trm = e.currentTarget.value
                setOpened(true)
                setInputValue(trm)
                const res = await MachshevetClient.FindEmailAddresses(trm)
                setOptions(res!)
            }} onFocus={() => setOpened(true)} onKeyDown={e => {
                if (e.key == "Enter" && inputValue != "" && options.length == 0) {
                    addEmail(inputValue)
                    setInputValue("")
                }
            }} />
            {opened && <div>
                {options.map(x => <div key={x.Key} style={{ display: "flex" }} onClick={e => {
                    setInputValue("")
                    addEmail(x.Key)
                }}><div style={{ fontWeight: "bold" }}>{x.Value}</div> ({x.Key})</div>)}
            </div>}
        </div>
    </div>
}

export const EditTable: FC<ControlProps2> = props => {
    //const [selectedID, setSelectedID] = useState<number>()
    const [commands, setCommands] = useState<ColumnData[]>()
    const ctx = useContext(MainContext)
    const rows: RecordsData = props.Value

    useEffect2(async () => {
        if (!commands) {
            const cmnds = await MachshevetClient.Commands(props.gridProps!)
            setCommands(cmnds)
        }
    }, [])

    const gp = props.gridProps
    gp!.SettingGroup = SettingGroup.EditColumns

    const cmds = commands && prioritizeCommands(ctx, commands)
    const cmds2 = commands && prioritizeCommands(ctx, commands.filter(x => !x.NeedsRecord && x.NeedsField))

    return <table {...testAttribs(props)} onContextMenu={e => ctx.showContextMenu!(e, undefined, cmds, undefined, undefined, undefined, props.reloader, gp)}>
        <thead>
            <tr>
                {rows.Columns.filter(x => x.Visible !== false).map(x => {
                    return <th key={x.Name} title={x.Tooltip} id={"TestColumn_" + x.Name} >
                        <SmartDiv style={{ display: 'inline' }} onContextMenu={e => ctx.showContextMenu!(e, undefined, cmds2, undefined, undefined, undefined, undefined, gp, x)}> {x.LocalName}</SmartDiv>
                    </th>
                })}
                <th />
            </tr>
        </thead>
        <tbody>
            {rows.Records.map((x, i) => {
                const flds = getControlProps3(x, rows.Columns)
                return <tr id={"Test" + i} key={x.RecordID} style={{ position: 'relative' }} onContextMenu={e => {
                    ctx.showContextMenu!(e, undefined, cmds, undefined, undefined, undefined, props.reloader, gp, undefined, x.RecordID)
                    //setSelectedID(x.RecordID)
                }}>{flds.filter(x => x.memberData!.Visible !== false).map(y => {
                    y.recordID = x.RecordID
                    y.reloader = props.reloader
                    y.mainRecordType = props.recordType
                    y.editPage = props.editPage
                    y.recordType = rows.RecordType
                    const subgp = new GridProps()
                    subgp.RecordValues = controlRecord2(flds, x.RecordID!)
                    subgp.RecordValues![props.memberData.NavigationField!] = props.recordID
                    //if (props.commandInputGetter) subgp.CommandValues = props.commandInputGetter()
                    subgp.ParentMember = props.memberData.Name
                    subgp.CommandValues = props.gridProps?.CommandValues
                    subgp.Member = y.memberData.Name
                    subgp.ParentRecordType = y.mainRecordType
                    y.gridProps = subgp
                    y.onChange = async v => await props.onChange!(v, y, undefined, i)
                    return <td key={y.Key} id={"Test_Control_" + y.memberData.Name} data-Editable={y.memberData.Editable} onContextMenu={e => {
                        ctx.showContextMenu!(e, undefined, cmds, y, undefined, undefined, props.reloader, gp)
                        //setSelectedID(x.RecordID)
                    }}>
                        <Control field={y} />
                    </td>
                })}
                    <td id="Test_Tools" style={{ whiteSpace: 'nowrap' }}>
                        {flds.filter(y => y.ErrorText).map(y => <span id={"Test_Error_" + y.memberData.Name} key={y.Key} style={{ color: "red" }}>{y.ErrorRecordID ? <NavLink recordType={x.RecordType} recordID={y.ErrorRecordID} >{y.ErrorText}</NavLink> : <span>{y.memberData!.LocalName + " " + y.ErrorText}</span>}</span>)}
                        <Icon name="Delete" onClick={async () => {
                            const currows = rows.Records
                            const v = currows.map(y => {
                                if (y.RecordID === x.RecordID) y.IsDeleted = true
                                return y
                            })
                            const newstt = rows
                            rows.Records = v
                            await props.onChange!(newstt, props)
                        }} />
                    </td>
                </tr>
            }
            )}
        </tbody>
        <tfoot>
            <tr id="TestPlusRow" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <td>
                    <Icon name='Add' onClick={async () => {
                        const newrow = new LiteRecordData()
                        newrow.Fields = rows.SampleRecord
                        const newnums = rows ? rows.Records.filter(x => (x.RecordID || 0) < 0).map(x => x.RecordID!) : []
                        const minnum = newnums.length ? Math.min(...newnums) : 0
                        newrow.RecordID = minnum - 1
                        await props.onChange!(undefined, props, newrow)
                    }} />
                    {rows && <span id="TestTotalRows" style={{ color: 'lightgray' }}>{rows.Records.length}</span>}
                </td>
            </tr>
        </tfoot>
    </table>
}

export const DashboardButton: FC<DashboardTile> = props => {
    const ctx = useContext(MainContext)!
    const forecolor = numberColor(props.ForeColor || ctx.data.PrimaryColor)
    const backcolor = props.BackColor ? numberColor(props.BackColor) : undefined;
    const iconName = props.RecordType!;
    const hascount = props.Count !== undefined && props.Count !== 0
    return <SmartLink reportID={props.ReportID} controller={props.RecordType} style={{ color: forecolor, backgroundColor: backcolor, boxShadow: "0 0 5px rgba(0, 0, 0, .4)", padding: "3ch", alignItems: "center", display: "flex", flexDirection: 'column', position: 'relative', justifyContent: "center" }} >

        {hascount && <span style={{ color: "white", backgroundColor: "red", padding: 4, borderRadius: 30, position: 'absolute', display: 'block', top: "1ch", insetInlineEnd: "1ch" }}>
            <SmartCount number={props.Count!} fieldType={props.AggregationFieldType} />
        </span>}

        {props.ReportType && [ReportTypes.LineChart, ReportTypes.PieChart].includes(props.ReportType) ? <MiniChart reportID={props.ReportID!} /> : <Icon name={iconName} fontSize="300%" />}
        <span>{props.DisplayName}</span>
        {props.Licensed === false ? <span >🔒</span> : undefined}
    </SmartLink>
}

export const MiniChart: FC<{ reportID: number }> = props => {
    const ctx = useContext(MainContext)
    const lastRefresh = useRef<number>(0)
    const [data, setData] = useState<RecordsData>()
    const [gridProps, setGridProps] = useState<GridProps>()

    useInterval(async () => {
        if (ctx.docActive()) {
            const diff = Date.now() - lastRefresh.current
            if (diff > 9000) {
                const gp = new GridProps()
                gp.ReportID = props.reportID
                const dat = await MachshevetClient.QuickSearch(gp)
                gp.DisplayType = dat.ReportType
                const recs = dat.Records
                setData(recs)
                lastRefresh.current = Date.now()
                setGridProps(gp)
            }
        }
    }, 500, true)
    return <ChartBox records={data} showTools={false} gridProps={gridProps} />
}

export const Dashboard: FC = () => {
    const ctx = useContext(MainContext)!
    const [state, setState] = useState<DashboardData>()

    async function getData() {
        const dt = await MachshevetClient.GetDashboardData()
        setState(dt)
    }

    useEffect2(async () => {
        ctx.setPageTitle!(ctx.localized("Home"))
        await getData()//this line works, but very slow
    }, [])

    useInterval(async () => {
        if (ctx.docActive()) {
            await getData()
        }
    }, 10000, true)

    const imgurl = getGlobalUrl('DashboardBackground')
    return <div style={{ padding: 30, backgroundImage: `url(${imgurl})`, backgroundRepeat: "no-repeat", backgroundPosition: 'center', backgroundSize: '60%', overflowY: 'auto' }}>
        <div style={{ display: 'grid', gridTemplateColumns: "repeat(auto-fit, minmax(6em, 15em))", gap: "3vmax", backgroundColor: "transparent" }}  >
            {state && state.Entities.map(x => <DashboardButton {...x} key={x.Key} />)}
            <div id="TestEditTiles" style={{ boxShadow: "0 0 5px rgba(0, 0, 0, .4)", textAlign: "center", padding: "1.5vw", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', background: '#e5fafd' }} >
                <ColumnChooser Group={SettingGroup.Dashboard} onChange={getData} />
                <span style={{ marginTop: 10 }}> {ctx.localized("EditTiles")}</span>
            </div>
        </div>
        <div style={{ margin: '80px 0 20px', fontWeight: 600, fontSize: '110%' }}>{ctx.localized('Recent')}</div>
        <div style={{ display: 'flex', rowGap: 20, columnGap: 30, flexWrap: 'wrap' }}>
            {ctx.data.Recents?.map(x => {
                const backcolor = x.BackColor ? numberColor(x.BackColor) : undefined
                const iconName = x.RecordType!
                return <NavLink key={x.RecordType + '.' + x.RecordID} recordType={x.RecordType} recordID={x.RecordID} style={{ backgroundColor: backcolor, textAlign: "center", padding: '16px 10px', background: '#e7e7e7', display: 'flex', alignItems: 'center', borderInlineStartColor: numberColor(ctx.data.SecondaryColor), borderInlineStartWidth: 3, borderInlineStartStyle: 'solid', flexGrow: 1 }}>
                    <Icon name={iconName} />
                    <span>{x.DisplayName}</span>
                    <span style={{ color: numberColor(ctx.data.SecondaryColor), display: 'flex', flexGrow: 1, justifyContent: 'end', marginInlineEnd: '-23px ' }}>
                        <span style={{
                            border: '2px solid ' + numberColor(ctx.data.SecondaryColor), borderRadius: '50%', background: '#fff'
                        }}> {ctx.data.IsRtl ? ">>" : "<<"}</span>
                    </span>
                </NavLink>
            })}
        </div>
    </div>
}

export const Login: FC = () => {
    const ctx = useContext(MainContext)
    const [uName, setUName] = useState<string>("")
    const [pass, setPass] = useState<string>("")
    const [result, setResult] = useState<LoginResult>()
    const [newPass, setNewPass] = useState("")
    useEffect(() => { ctx.setPageTitle!(ctx.localized("Login")) })

    return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'gainsboro', flexGrow: 1 }}>
        <div style={{ gap: "5vh", backgroundColor: 'white', display: 'flex', borderRadius: 8, alignItems: "center", padding: "3vh" }}>
            <div style={{ textAlign: 'center' }}><img src={getGlobalUrl('Logo')} /></div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: "1vh" }}>

                {ctx.data.EnablePasswordLogin && <>
                    <div style={{ flexDirection: 'column', display: 'flex', borderRadius: 5, backgroundColor: 'white' }}>
                        <label>{ctx.localized("Email") + ":"}</label>
                        <SmartInput id="TestEmail" placeholder={ctx.localized("Email")} onChange={e => setUName(e.currentTarget.value)} />
                    </div>

                    <div style={{ flexDirection: 'column', display: 'flex', borderRadius: 5, backgroundColor: 'white' }}>
                        <label>{ctx.localized("Password") + ":"}</label>
                        <PasswordPlus id="TestPassword" onChange={e => setPass(e.currentTarget.value)} />
                    </div>

                    <SmartButton testName="Login" onClick={async () => {
                        const res = await MachshevetClient.Login(uName, pass)
                        setResult(res)
                        if (!res.MustReset && res.PersonID) {
                            ctx.setUser(res.PersonID, uName)
                            if (!res.Validation) redirect()
                        }
                    }} autoFocus={true}>{ctx.localized("Login")}</SmartButton>

                    {ctx.data.HasMailbox && <SmartButton testName="GetNewPassword" onClick={async () => {
                        const cnfm = showConfirm(ctx.localized("GenerateANewPassword") + "?")
                        if (cnfm) await MachshevetClient.GenerateTempPassword(uName)
                    }}>{ctx.localized("GetNewPassword")}</SmartButton>}

                    <div id="TestError" style={{ padding: 5, color: 'red', fontWeight: 'bold' }}>{result?.Error}</div>

                    {result?.Validation || result?.MustReset && <div>
                        <div style={{ fontSize: "150%", color: "orange", fontWeight: 'bold' }}>{result.Validation}</div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {ctx.localized("NewPassword")}
                            <input value={newPass} type="password" onChange={e => setNewPass(e.currentTarget.value)} />
                            <SmartButton onClick={async () => {
                                await MachshevetClient.ChangePassword(newPass)
                                redirect()
                            }}>{ctx.localized("Change")}</SmartButton>
                        </div>
                    </div>}
                </>}

                <SmartButton style={{ display: 'flex' }} onClick={async () => {
                    const url = await MachshevetClient.MicrosoftLogin()
                    window.location.href = url
                }}>
                    <Icon name="Microsoft" /> {ctx.localized("MicrosoftLogin")}
                </SmartButton>
            </div>
        </div>
    </div>
}

export const Icon: FC<{ name: string, onClick?: MouseEventHandler, onMouseEnter?: MouseEventHandler, onMouseLeave?: MouseEventHandler, title?: string, fontSize?: string, color?: string, visibility?: 'visible' | 'hidden' }> = props => {
    const ctx = useContext(MainContext)
    const [text, setText] = useState<string>()
    const [hover, setHover] = useState<boolean>()

    //we must use an svg string, otherwise fill:currentcolor has no effect
    useEffect2(async () => {
        const tx = await MachshevetClient.VectorIcon(props.name)
        setText(tx)
    }, [props.name])

    let svg2 = text || ""
    if (props.title) svg2 = svg2 + `<title>${props.title}</title>`
    const stl: CSSProperties = { height: "1em", width: "1em", display: "flex" }
    if (props.color) stl.fill = props.color
    if (props.fontSize) stl.fontSize = props.fontSize
    if (props.visibility) stl.visibility = props.visibility
    if (hover && props.onClick) stl.color = numberColor(ctx.data.SecondaryColor)
    return <span id={"TestIcon" + props.name} title={props.title} dangerouslySetInnerHTML={{ __html: svg2 }} style={stl} onClick={e => {
        setHover(false)
        if (props.onClick) props.onClick(e)
    }} onMouseEnter={e => {
        setHover(true)
        if (props.onMouseEnter) props.onMouseEnter(e)
    }} onMouseLeave={e => {
        setHover(false)
        if (props.onMouseLeave) props.onMouseLeave(e)
    }} aria-label={props.title} />
}

export const DialogPlus: FC<{ title?: string, footer: ReactNode, onClose: () => void, shouldClose?: () => boolean, children?: ReactNode, minWidth?: number, testName?: string }> = props => {
    const ctx = useContext(MainContext)
    const dialogRef = useRef<HTMLDialogElement>(null)
    const timeref = useRef(new Date().getTime())
    const [position, setPosition] = useState({ x: 0, y: 0 })
    //const [dragging, setDragging] = useState(false)
    //const [startPosition, setStartPosition] = useState({ x: 0, y: 0 })
    const [offset, setOffset] = useState({ x: 0, y: 0 })

    const sDialog: CSSProperties = { display: 'flex', flexDirection: 'column', maxHeight: "80vh", maxWidth: "80vw", color: numberColor(ctx.data.PrimaryColor), backgroundColor: "white" }
    sDialog.zIndex = 2//needed only when not using showmodal

    if (props.minWidth) sDialog.minWidth = props.minWidth + "vw"
    if (position.x) {
        sDialog.left = position.x
        sDialog.top = position.y
        //sDialog.transform = `translate(${position.x}px, ${position.y}px)`
    }

    const handleMouseUp = () => {
        //setDragging(false)
        window.removeEventListener("mouseup", handleMouseUp)
        window.removeEventListener("mousemove", handleMouseMove)
    }
    const handleMouseMove = (e: MouseEvent) => {
        //if (dragging) {
        const newX = e.clientX - offset.x  // Adjust with the offset
        const newY = e.clientY - offset.y  // Adjust with the offset
        setPosition({ x: newX, y: newY })  //
        console.log("Mouse Position:", { x: newX, y: newY });
        //console.log("Start Position:", startPosition);
        console.log("New Position:", { x: newX, y: newY });
        //}
    };

    //position absoulte doesnt work in columnchooser in attention2
    const outstl: CSSProperties = { position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: "rgba(0,0,0,.45)", zIndex: 3, overflowY: 'auto' }
    return <div style={outstl}>
        <dialog id={"TestPopup" + (props.testName || "")} className={props.title} data-time={timeref.current} ref={dialogRef} style={sDialog}>
            <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 'bold', fontSize: 16, borderBottom: "1px solid #f0f0f0" }} >
                <div style={{ cursor: 'move', padding: "1ch", flexGrow: 1 }} onMouseDown={e => {
                    //setDragging(true)
                    //const strtpos = { x: e.clientX - position.x, y: e.clientY - position.y }
                    //ctx.devLog!("strtpos", strtpos)
                    //setStartPosition(strtpos)

                    const dialogRect = dialogRef.current?.getBoundingClientRect()
                    if (dialogRect) {
                        const offsetX = e.clientX - dialogRect.left
                        const offsetY = e.clientY - dialogRect.top
                        setOffset({ x: offsetX, y: offsetY })  // Store the offset
                    }

                    window.addEventListener("mouseup", handleMouseUp)
                    window.addEventListener("mousemove", handleMouseMove)
                }}>{props.title}</div>
                <span id="TestCloseDialog" style={{ padding: 10, cursor: 'pointer' }} onClick={e => {
                    if (props.shouldClose) if (!props.shouldClose()) return
                    (dialogRef.current as any).close()
                    props.onClose()
                }}>X</span>
            </div>
            <div style={{ padding: "1vw", overflowY: 'auto', display: 'flex', backgroundColor: 'transparent' }} >{props.children}</div>
            <div style={{ padding: 10, borderTop: "1px solid #f0f0f0", display: 'flex', justifyContent: 'end', gap: 4, backgroundColor: 'transparent' }} >
                {props.footer}
            </div>
        </dialog >
    </div >
}

export const SmartButton: FC<HTMLProps<HTMLButtonElement> & { testName?: string, buttonStyle?: boolean }> = props => {
    const ctx = useContext(MainContext)
    const [isWorking, setIsWorking] = useState(false)
    const [hover, setHover] = useState(false)
    const isLocked = (isWorking || props.disabled)
    const stl = { ...props.style }
    if (props.buttonStyle !== false) {
        stl.borderWidth = 1
        stl.borderRadius = 5
        stl.borderColor = numberColor(ctx.data.PrimaryColor)
    } else {
        stl.borderWidth = 0
    }
    if (isLocked) {
        stl.cursor = isWorking ? 'wait' : 'not-allowed'
        stl.color = "lightgray"
        stl.borderColor = "lightgray"
    }
    else {
        if (hover) stl.backgroundColor = adjustColor(ctx.data.SecondaryColor!, .6)
    }

    return <button id={"Test" + props.testName} style={stl} disabled={isLocked} autoFocus={props.autoFocus} onMouseEnter={e => {
        setHover(true)
        if (props.onMouseEnter) props.onMouseEnter(e)
    }} onMouseLeave={e => {
        setHover(false)
        if (props.onMouseLeave) props.onMouseLeave(e)
    }} onClick={async e => {
        setIsWorking(true)
        if (props.onClick) {
            try {
                await props.onClick(e)
            } catch (e) {
                setIsWorking(false)
                throw e
            }
        }
        setIsWorking(false)
    }}>{props.children}</button>
}

export const EditDialog: FC<RecordFormProps> = props => {
    const record = props.record
    const [IsDirty, setIsDirty] = useState(false)
    const [saveHandler, setSaveHandler] = useState<() => number>()
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)!
    const rec = record?.Records[0]
    const ttl = rec ? (rec.RecordName || rec.RecordID) + ' [' + ctx.localized(rec!.RecordType!) + ']' : ""
    const recid = rec?.RecordID

    return <DialogPlus title={ttl} minWidth={80} onClose={props.onClose!} shouldClose={() => {
        if (IsDirty) {
            const sure = showConfirm(ctx.localized('CloseWithoutSaving') + '?');
            return sure
        }
        else return true
    }}
        footer={[
            <SmartButton key="Open" testName="Open" onClick={() => {
                //redirect(undefined, props.record?.RecordType, recid || 0, ctx.data.CommandsInSameTab)
                goToRecord(rec!, ctx.data.CommandsInSameTab)
                props.onClose!()
            }}>{ctx.localized('Open')}</SmartButton>,
            <SmartButton key="SaveAndCopy" testName="SaveAndCopy" onClick={async e => {
                ctx.devLog!("saveandcopy clicked")
                const id = await saveHandler!()
                if (!id) { return; }
                const cmd = new ColumnData2()
                cmd.Name = "Copy"
                const gp = new GridProps()
                gp.RecordKeys = new Set([id.toString()])
                gp.RecordType = props.record?.RecordType
                props.onClose!()
                await doCommand(cmd, 0, gp, app, ctx)
            }}>{ctx.localized('SaveAndCopy')}</SmartButton>,
            <SmartButton key="SaveAndOpen" testName="SaveAndOpen" onClick={async e => {
                const id = await saveHandler!();
                if (!id) { return; }
                redirect(undefined, props.record?.RecordType, id, ctx.data.CommandsInSameTab)
                props.onClose!()
            }}>{ctx.localized('SaveAndOpen')}</SmartButton>,
            <SmartButton key="Save" testName="Save" onClick={async () => {
                await saveHandler!();
                props.onClose!()
            }}>{ctx.localized('Save')}</SmartButton>,
        ]}    >
        <RecordForm {...props} onDirty={setIsDirty} setSaveHandler={setSaveHandler} />
        {rec && <div style={{ position: 'absolute', opacity: .1, bottom: 0, insetInlineEnd: 0, zIndex: -1 }}> <Icon name={rec.RecordType!} fontSize="900%" /></div>}
    </DialogPlus>
}

//export const NavLink: FC<React.HTMLProps<HTMLAnchorElement> & { reportID?: number, recordType?: string, recordID?: number, action?: string, activeTab?: number, foreignController?: string, foreignRecordID?: number }> = props => {
//    const url = getClientUrl(props.reportID, props.recordType, props.recordID, props.action, props.activeTab, props.foreignController, props.foreignRecordID);
//    const stl: CSSProperties = { ...props.style, textDecoration: "none" }
//    return <Link id={"TestLink" + props.recordType + "_" + props.action} title={props.title} to={url} onClick={props.onClick} style={stl} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>{props.children}</Link >
//}
export const NavLink: FC<{ children?: ReactNode, style?: CSSProperties, title?: string, reportID?: number, recordType?: string, recordID?: number, action?: string, activeTab?: number, foreignController?: string, foreignRecordID?: number, onClick?: MouseEventHandler }> = props => {
    const url = getClientUrl(props.reportID, props.recordType, props.recordID, props.action, props.activeTab, props.foreignController, props.foreignRecordID);
    const stl: CSSProperties = { ...props.style, textDecoration: "none" }
    return <Link id={"TestLink" + props.recordType + "_" + props.action} title={props.title} to={url} style={stl} onClick={props.onClick} onDoubleClick={e => e.stopPropagation()}>{props.children}</Link >
}

export const SmartLink: FC<React.HTMLProps<HTMLAnchorElement> & { reportID?: number, controller?: string, recordID?: number }> = props => {
    const ctx = useContext(MainContext)
    const [hover, setHover] = useState(false)
    const url = getClientUrl(props.reportID, props.controller, props.recordID)
    const stl = { ...props.style } || {}
    if (hover) stl.backgroundColor = numberColor(ctx.data.SecondaryColor)
    stl.textDecoration = "none"
    return <Link id={"TestLink" + props.controller + "_" + props.action} title={props.title} to={url} onClick={props.onClick} style={stl} onMouseEnter={e => {
        setHover(true)
        if (props.onMouseEnter) props.onMouseEnter(e)
    }} onMouseLeave={e => {
        setHover(false)
        if (props.onMouseLeave) props.onMouseLeave(e)
    }}>{props.children}</Link >
}

export const SmartCheckBox: FC<HTMLProps<HTMLSpanElement> & { checked: boolean }> = props => {
    return <span {...props}>{props.checked ? "☑" : "☐"}</span>
}

export const SmartDiv: FC<HTMLProps<HTMLDivElement>> = props => {
    const ctx = useContext(MainContext)
    const [hover, setHover] = useState(false)
    const [canDrop, setCanDrop] = useState(false)
    const [target, setTarget] = useState<EventTarget>()
    const stl = { ...props.style } || {}
    if (hover) stl.backgroundColor = numberColor(ctx.data.SecondaryColor)
    stl.cursor = 'pointer'
    stl.userSelect = 'none'

    if (props.disabled) {
        stl.cursor = 'not-allowed'
        stl.color = "lightgray"
        stl.borderColor = "lightgray"
        stl.fill = "lightgray"
    }
    if (props.draggable) stl.cursor = 'grab'
    if (props.onDrop && canDrop) {
        stl.borderStyle = 'dashed'
        stl.borderColor = 'gray'
        stl.borderWidth = 2
    }


    return <div {...props} style={stl} onMouseEnter={e => {
        setHover(true)
        if (props.onMouseEnter) props.onMouseEnter(e)
    }} onMouseLeave={e => {
        setHover(false)
        if (props.onMouseLeave) props.onMouseLeave(e)
    }} onDragEnter={e => {
        ctx.devLog!("dragenter", e)
        setTarget(e.target)
        setCanDrop(true)
    }} onDragLeave={e => {
        ctx.devLog!("dragleave", e)
        if (!e.currentTarget.contains(e.relatedTarget as Node)) setCanDrop(false)
    }} />
}

export const PasswordPlus: FC<HTMLProps<HTMLInputElement>> = props => {
    const ctx = useContext(MainContext)
    const [showPassword, setShowPassword] = useState(false)
    const { ref, ...props2 } = props
    return <div style={{ display: "flex" }}>
        <SmartInput {...props2} type={showPassword ? "text" : "password"} placeholder={ctx.localized("Password")} />
        <Icon name={showPassword ? "Hide" : "Show"} onClick={() => setShowPassword(!showPassword)} />
    </div>
}

export const SmartInput = forwardRef<HTMLInputElement, HTMLProps<HTMLInputElement>>((props, forwardedRef) => {
    const ctx = useContext(MainContext)
    const [focused, setFocused] = useState(false)
    const [text, setText] = useState("")
    const [calcWidth, setCalcWidth] = useState(0)
    const spanRef = useRef<HTMLSpanElement | null>(null)
    const inputRef = useRef<HTMLInputElement | null>(null)

    const stl = { ...inputStyle(props.style, ctx, focused) }

    function updateWidth() {
        const curspan = spanRef.current
        if (curspan) {
            curspan.innerText = text
            const wid = curspan.offsetWidth
            setCalcWidth(wid)
        }
    }


    useEffect(() => {
        if (ctx.data.AutoWidth) {
            updateWidth()
            const resizeObserver = new ResizeObserver(() => updateWidth())
            resizeObserver.observe(spanRef.current!)
            return () => resizeObserver.disconnect()
        } else
            return
    }, [text])

    const handleRef = (el: HTMLInputElement | null) => {
        if (el) {
            setText(el.value)
            inputRef.current = el
            if (typeof forwardedRef === 'function') {
                forwardedRef(el)
            } else if (forwardedRef) {
                forwardedRef.current = el
            }
        }
    }

    const mainstyle: CSSProperties = {}
    if (!ctx.data.AutoWidth) {
        stl.minWidth = `${text.length * 1.1}ch`
    } else {
        stl.width = calcWidth
        //stl.width = `max(${calcWidth}px,100%)`
        // mainstyle.flexGrow = 1//this messes up hamburger menu
    }
    const { width, flexGrow, ...spanstyle } = stl

    spanstyle.visibility = "hidden"
    spanstyle.height = 0


    spanstyle.paddingTop = 0
    spanstyle.paddingBottom = 0
    //spanstyle.padding = 0
    //spanstyle.flexGrow = undefined

    mainstyle.flexGrow = props.style?.flexGrow

    return <div style={{ ...mainstyle, display: "flex", flexDirection: "column" }}>
        <input {...props} ref={handleRef} autoComplete="off" style={stl} onBlur={e => {
            if (props.onBlur) props.onBlur(e)
            setFocused(false)
        }} onFocus={e => {
            e.target.select()
            if (props.onFocus) props.onFocus(e)
            setFocused(true)
        }} />
        <span ref={spanRef} style={spanstyle}></span>
    </div>
})
SmartInput.displayName = "SmartInput"

export const SmartSelect: FC<HTMLProps<HTMLSelectElement>> = props => {
    const ctx = useContext(MainContext)
    return <select {...props} style={inputStyle(props.style, ctx)} />
}

export const SmartImage: React.FC<{ src: string }> = props => {
    const imgRef = useRef<HTMLImageElement>(null)
    const adjustSize = () => {
        const img = imgRef.current
        if (img && img.parentElement) {
            const parentHeight = img.parentElement.offsetHeight
            img.style.maxHeight = `${parentHeight}px`
            const parentWidth = img.parentElement.offsetWidth
            img.style.maxWidth = `${parentWidth}px`
        }
    };

    useEffect(() => {
        adjustSize();
        //window.addEventListener('resize', adjustImageHeight);
        //return () => window.removeEventListener('resize', adjustImageHeight);
    }, [])
    useEventListener("resize", adjustSize)

    return <img {...props} ref={imgRef} style={{ height: 'auto', maxHeight: '100%', width: 'auto' }} />
}

export const SmartRow: FC<HTMLProps<HTMLTableRowElement>> = props => {
    const ctx = useContext(MainContext)
    const [hover, setHover] = useState(false)
    const rowRef = useRef<HTMLTableRowElement>(null)

    const stl = { ...props.style } || {}
    if (hover) stl.backgroundColor = numberColor(ctx.data.SecondaryColor)
    stl.userSelect = 'none'

    useEffect(() => {
        const handleDocumentMouseMove = (e: MouseEvent) => {
            if (rowRef.current && !rowRef.current.contains(e.target as Node)) {
                setHover(false)
            }
        }
        if (hover) {
            document.addEventListener('mousemove', handleDocumentMouseMove)
        } else {
            document.removeEventListener('mousemove', handleDocumentMouseMove)
        }
        return () => document.removeEventListener('mousemove', handleDocumentMouseMove)
    }, [hover])


    return <tr {...props} ref={rowRef} style={stl} onMouseOver={e => {
        setHover(true)
        if (props.onMouseOver) props.onMouseOver(e)
    }}

    />
}

export const SmartCount: FC<{ number?: number, testname?: string, fieldType?: FieldTypes, coinSymbol?: string, interval?: Intervals, letBold?: boolean }> = props => {
    const ctx = useContext(MainContext)!
    const [counter, setCounter] = useDebuggableState(props.number || 0)
    const [prevVal, setPrevVal] = useState(props.number || 0)
    const [changing, setChanging] = useState(false)
    const hasval = props.number !== null && props.number !== undefined

    useEffect(() => {
        if (hasval) {
            const newval = +props.number!
            let tckr = prevVal
            const fctr = newval! < prevVal! ? -1 : 1
            const diff = Math.abs(newval! - prevVal!)
            const ticks = 10
            const adder = Math.round(diff / ticks) || 1
            const timer = setInterval(() => {
                if (tckr === newval) {
                    clearInterval(timer)
                    setPrevVal(props.number || 0)
                    setChanging(false)
                } else {
                    setChanging(true)
                    tckr += (adder * fctr)
                    if ((fctr === 1 && tckr > newval!) || (fctr === -1 && tckr < newval!)) tckr = newval!
                    if (tckr === undefined) {
                        const a = 1
                    }
                    setCounter(tckr)
                }
            }, 90)
        }
    }, [props.number])

    let dspl = hasval ? counter!.toString() : ""
    if (counter) {
        switch (props.fieldType) {
            case FieldTypes.Span:
                {
                    const unts = parsedSeconds(counter, props.interval)
                    dspl = formattedNumber(ctx, unts.Value) + ' ' + ctx.localized(Intervals[unts.Key] + 's')
                }
                break
            case FieldTypes.Percent:
                dspl = formattedNumber(ctx, counter * 100) + '%'
                break
            case FieldTypes.Money:
                {
                    const smb = props.coinSymbol || ctx.data.CoinSymbol
                    const num = formattedNumber(ctx, counter)
                    dspl = smb + ' ' + num
                }
                break
        }
    }
    return <span id={"Test" + props.testname} data-value={props.number} style={{ fontWeight: changing && props.letBold !== false ? "bold" : "inherit" }}>{dspl}</span>
}

export const SmartText: FC<{ Text?: string, testname?: string }> = props => {
    const [display, setDisplay] = useState(props.Text || "")
    const [prevVal, setPrevVal] = useState(props.Text || "")
    const [changing, setChanging] = useState(false);
    const hasval = props.Text !== null && props.Text !== undefined

    useEffect(() => {
        const newval = props.Text || ""

        if (hasval) {
            let tckr = prevVal!
            let pos = 0;
            const maxlen = Math.max(newval.length, prevVal.length)
            const ticks = 10
            const tempo = Math.round(maxlen / ticks)
            const timer = setInterval(() => {
                if (tckr === newval) {
                    clearInterval(timer)
                    setPrevVal(props.Text || "")
                    setChanging(false)
                } else {
                    setChanging(true)
                    pos += tempo
                    if (pos > maxlen) pos = maxlen
                    tckr = newval.substring(0, pos) + prevVal.substring(pos, prevVal.length)
                    setDisplay(tckr)
                }
            }, 150)
        }
    }, [props.Text]);
    return <span id={"Test" + props.testname} style={{ fontWeight: changing ? "bold" : "inherit" }}>{display}</span>
}

export const MessageEditor: FC<CommandMessage & { onClose: () => void }> = props => {
    const ctx = useContext(MainContext)
    const [state, setState] = useState(props)
    const fileInput = React.useRef<HTMLInputElement>(null)
    const htmcol = new ColumnData()

    return <DialogPlus {...props} title={ctx.localized('NewMessage')} footer={[<SmartButton testName="AddDocument" key="ShowFiles" onClick={() => {
        const gp = new GridProps()
        gp.RecordType = "Document"
        ctx.openTable!(gp, e => setState(prev => ({ ...prev, Attachments: prev.Attachments.concat({ ...new Attachment(), Name: e.RecordName!, RecordID: e.RecordID }) })))
    }} >{ctx.localized('AddDocument')}</SmartButton>, <input id="TestUpload" key="Upload" type="file" ref={fileInput} onChange={async e => {
        const fil = (e.target as HTMLInputElement).files![0];
        const bts = await GetFileBytes(fil)
        const filnam = fil.name.replace(/\.[^/.]+$/, "")
        const ext = fil.name.substr(fil.name.lastIndexOf('.') + 1)
        setState(prev => ({ ...prev, Attachments: prev.Attachments.concat({ ...new Attachment(), Name: filnam, Bytes: bts, Type: ext }) }));
    }} />, <SmartButton testName="Send" key="Send" onClick={async () => {
        await MachshevetClient.SendMail(state)
        props.onClose()
    }}>{ctx.localized('Send')}</SmartButton>]}>
        <div>
            <table style={{ width: "100%" }}>
                <tbody>
                    <tr><td>{ctx.localized('ScreenName')}</td><td>
                        <SmartInput value={state.ScreenName} onChange={e => {
                            const vl = e.currentTarget.value;
                            setState(prev => ({ ...prev, ScreenName: vl }));
                        }} />
                    </td></tr>
                    <tr><td>{ctx.localized('ReplyAddress')}</td><td>
                        <SmartInput value={state.ReplyAddress} onChange={e => {
                            const vl = e.currentTarget.value;
                            setState(prev => ({ ...prev, ReplyAddress: vl }));
                        }} />
                    </td></tr>
                    <tr><td>{ctx.localized('Recipients')}</td><td>
                        <EmailDropDown {...new ControlProps2()} onChange={async e => setState(prev => ({ ...prev, Recipients: e }))} Selected={state.Recipients} />
                        <input type="checkbox" checked={state.SendSplit} onChange={e => {
                            const chk = e.currentTarget.checked
                            setState(prev => ({ ...prev, SendSplit: chk }))
                        }} /><span>{ctx.localized("SendSplit")}</span>
                    </td></tr>
                    <tr><td>{ctx.localized('Fax')}</td><td> <SmartInput value={state.FaxNumber} onChange={x => {
                        const vl = +x.currentTarget.value;
                        setState(prev => ({ ...prev, FaxNumber: vl }));
                    }} /></td></tr>
                    <tr><td>{ctx.localized('Subject')}</td><td> <SmartInput value={state.Subject} onChange={x => {
                        const vl = x.currentTarget.value;
                        setState(prev => ({ ...prev, Subject: vl }));
                    }} /></td></tr>
                </tbody>
            </table>
            <HtmlPick {...new ControlProps2()} memberData={htmcol} Value={state.Body} isEditable={true} onChange={async e => {
                setState(prev => ({ ...prev, Body: e as string }));
            }} />
            {state.Attachments.length > 0 && <div>
                <div style={{ paddingRight: 5 }}>{ctx.localized('Attachments')}:</div>
                <div id="TestAttachments" style={{ padding: 5 }}>{state.Attachments.map(x =>
                    <div key={x.Name} style={{ display: "flex", gap: 7, justifyContent: "space-between", boxShadow: "1px 1px 6px 0px #a1a1a1", borderRadius: 3, margin: 1, padding: "2px 4px" }}>
                        <div style={{ display: "flex", gap: 3 }}>
                            <div>🗎</div>
                            <div>{x.Name + '.' + x.Type}</div>
                        </div>
                        <div style={{ cursor: "pointer" }} onClick={() => setState(prev => ({ ...prev, Attachments: prev.Attachments.filter(a => a.Name !== x.Name) }))}>&#10006;</div>
                    </div>)}
                </div>
            </div>}
        </div>
    </DialogPlus>
}

export const ContextMenu: FC<ContextMenuProps> = props => {
    const ctx = useContext(MainContext)!;
    const stl: CSSProperties = {}
    stl.borderRadius = 6
    stl.backgroundColor = "white"
    stl.borderColor = numberColor(ctx.data.SecondaryColor)
    stl.borderStyle = "solid"
    stl.borderWidth = 1
    stl.backgroundColor = "white"
    stl.fontSize = "90%"
    stl.overflowY = "auto"
    stl.zIndex = 3

    const qads = props.quickAdds?.map(x => {
        const cp = new ColumnData2()
        //cp.RecordType = props.items[0].RecordType
        cp.Name = 'Add'
        cp.LocalName = '+' + ctx.localized(x.Key)
        cp.Key = x.Key
        cp.commandParams = [x.Key + '.' + x.Value]
        return cp
    })

    return <Popout id="TestContextMenu" style={stl} position={props.position} >
        <div style={{ display: 'flex', gap: 4, padding: 1, justifyContent: "space-evenly", backgroundColor: adjustColor(ctx.data.SecondaryColor!, .7) }}>{qads && qads.map(x => <SmartDiv key={x.commandParams![0]} title={x.LocalName} style={{ padding: 3 }}> <Icon name={x.Key!} onClick={() => props.onCommand(x)} /></SmartDiv>)}</div>
        {props.items.map(x => {
            return <MenuItemView key={x.Name} item={x} onCommand={props.onCommand} style={{ whiteSpace: 'nowrap' }} gridProps={props.gridProps} />
        })}
    </Popout>
}

export const VerticalMenu: FC<MenuProps & HTMLProps<HTMLElement>> = props => {
    return <div style={props.style}>
        {props.items.map(x => <MenuItemView key={x.Name} item={x} onCommand={props.onCommand} gridProps={props.gridProps} />)}
    </div>
}

export const MenuItemView: FC<{ item: ColumnData2, onCommand: (command: ColumnData2) => void, gridProps?: GridProps } & HTMLProps<HTMLElement>> = props => {
    const [selected, setSelected] = useState(false)
    const [picklist, setPicklist] = useState<PickListItem[]>()
    if (props.item.items) {
        //this is for "More"
        const stl = { ...props.style }
        stl.display = selected ? 'block' : 'none'
        return <CommandItem  {...props.item} onClick={() => { }} onMouseEnter={() => setSelected(true)} onMouseLeave={() => setSelected(false)} style={{ display: 'flex' }}>
            <VerticalMenu items={props.item.items} hoverable onCommand={props.onCommand} style={stl} gridProps={props.gridProps} />
        </CommandItem>
    }
    return <CommandItem  {...props.item} style={props.style} onClick={async e => {
        if (props.item.HasPickList) {
            if (!selected) e.stopPropagation()
            setSelected(!selected)
            //const pl = await MachshevetClient.CommandOptions(props.item.RecordType!, props.item.Name!)
            //const pl = await MachshevetClient.CommandOptions(props.item.recordType!, props.item.Name!)
            const pl = await MachshevetClient.CommandOptions({ ...props.gridProps!, Member: props.item.Name })
            setPicklist(pl)
        }
        else {
            props.onCommand(props.item)
        }
    }}>

        {props.item.HasPickList && selected && <div>
            {picklist?.map(pick => {
                const cp = new ColumnData2()
                cp.LocalName = pick.Value
                cp.Name = props.item.Name
                cp.commandParams = [pick.Key]
                cp.Icon = pick.Icon || props.item.Icon
                return <CommandItem {...cp} style={props.style} key={pick.Key} onClick={e => {
                    props.onCommand && props.onCommand(cp)
                }}
                />
            })}
        </div>
        }
    </CommandItem>
}

export const CommandButton: FC<ColumnData2> = props => {
    const ctx = useContext(MainContext)
    const app = useContext(AppContext)!
    /*return <button type="button" style={{ padding: 2, textAlign: "inherit", width: "100%" }}    >*/
    return <MenuItemView item={props} onCommand={async () => {
        const gp = defaultGridProps(props.recordID)
        gp.RecordType = props.recordType
        await doCommand(props, 0, gp, app, ctx)
    }} />
    /*</button>*/
}

export const CommandItem: FC<ColumnData2 & { onClick: MouseEventHandler, style?: CSSProperties, icon?: string, iconWidth?: number, children?: ReactNode, onMouseEnter?: MouseEventHandler, onMouseLeave?: React.MouseEventHandler }> = props => {
    const ctx = useContext(MainContext)
    const stl = { ...props.style } || {}
    stl.gap = 3
    stl.display = 'flex'
    if (props.IsNew) stl.backgroundColor = "fuchsia"
    if (props.SpecificCommand) stl.fontWeight = 649
    let tnam = "_Field_" + props.Name
    if (props.commandParams?.length) tnam = tnam + '_' + props.commandParams[0]
    return <SmartButton style={stl} testName={tnam} buttonStyle={false} onClick={props.onClick} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
        <div style={{ display: 'flex', gap: ".2vw" }}>
            <Icon name={props.icon || props.Name!} />
            <span style={{ whiteSpace: 'nowrap' }}>{props.LocalName}</span>
            {(props.HasPickList || props.Name === "More") ? ctx.data.IsRtl ? '◁' : '▷' : ''}
        </div>
        {props.children}
    </SmartButton>
}

export const Avatar2: FC<ColumnData2 & { gridProps: GridProps, icon?: string, tooltip?: string, menuField?: ControlProps, params?: string[], totalRows?: number, children?: ReactNode }> = props => {
    const ctx = useContext(MainContext)
    const [subMenu, setSubMenu] = useState(false)
    const [inProgress, setInProgress] = useState(false)
    const [picklist, setPicklist] = useState<PickListItem[]>()
    const app = useContext(AppContext)!
    const stl: React.CSSProperties = { ...props.style, borderRadius: ".5vw", borderStyle: 'solid', padding: ".3vw .6vw", cursor: 'pointer', fontWeight: 600 }
    if (!stl.borderWidth) stl.borderWidth = 1
    stl.whiteSpace = "nowrap"
    stl.justifyContent = 'space-between'
    if (props.IsNew) stl.backgroundColor = "fuchsia"

    const hasrecs = props.gridProps.RecordKeys?.size || props.NeedsRecord === false

    const isenabled = !inProgress && hasrecs

    if (!isenabled) {
        stl.pointerEvents = "none"
        stl.opacity = 0.3
    }
    async function docmd(key?: string) {
        const gp2 = { ...props }
        if (key !== undefined) {
            gp2.gridProps.Member = undefined
            gp2.commandParams = [key]
        }
        try {
            //await doCommand({ ...gp2, commandParams: key ? [key] : props.commandParams }, props.totalRows || 0, props.gridProps, app, ctx)
            await doCommand(gp2, props.totalRows || 0, gp2.gridProps, app, ctx)
        } catch (e) {
            setInProgress(false)
            throw e
        }
        setInProgress(false)
    }

    return <SmartDiv style={{ ...stl, display: 'flex', justifyContent: 'space-evenly' }} disabled={props.CanExecute === false}
        onClick={async () => {
            if (props.HasPickList) {
                setSubMenu(!subMenu)
                if (!picklist) {
                    //const pl = await MachshevetClient.CommandOptions(props.gridProps.RecordType || props.recordType!, props.Name!)
                    const gp3 = { ...props.gridProps }
                    gp3.Member = props.Name
                    const pl = await MachshevetClient.CommandOptions(gp3)
                    setPicklist(pl)
                }

            } else {
                setInProgress(true)
                await docmd()
            }
        }}>
        <div style={{ display: 'flex', gap: 6, alignItems: 'center' }}>
            <Icon name={props.icon || props.Name!} />
            <div id={"Test_Field_" + props.Name} title={props.tooltip}>{props.LocalName}</div>
        </div>
        {subMenu && <div style={{ ...stl, position: 'absolute', backgroundColor: 'white', zIndex: 2 }} onMouseLeave={() => {
            setSubMenu(false)
        }}>{picklist?.map(x => <SmartDiv key={x.Key} id={"Test" + x.Key} style={{ padding: 2 }} onClick={async () => {
            setInProgress(true)
            await docmd(x.Key)
            setSubMenu(false)
        }}>{x.Value}</SmartDiv>)}</div>}
        {
            props.HasPickList ? <span>▼</span> : <></>
        }
        {props.children}
    </SmartDiv >;
}

export const HamburgerItem: FC<{ command: ColumnData2, onClick: (back: boolean) => void, onCommand: (command: ColumnData2, field?: ControlProps) => void }> = props => {
    const ctx = useContext(MainContext)
    if (props.command.items)
        //for "More"
        if (props.command.expanded && props.command.items) return <>
            <div>{ctx.localized(props.command.Name!)}</div>
            {props.command.items.map(subItem => <HamburgerItem key={subItem.Name} command={subItem} onClick={() => props.onClick(true)} onCommand={props.onCommand} />)}
        </>
        else return <CommandItem {...props.command} onClick={() => props.onClick(false)}  >
            {ctx.data.IsRtl ? '◁' : '▷'}
        </CommandItem>
    else {
        if (props.command.HasPickList)
            return <CommandItem {...props.command} onClick={() => props.onClick(false)} />
        else return <CommandItem {...props.command} onClick={async () => {
            await props.onCommand(props.command)
        }} />
    }
}

export const HamburgerMenu: FC<MenuProps> = props => {
    const ctx = useContext(MainContext)
    const [term, setTerm] = useState<string>()
    const [moreActive, setMoreActive] = useState(false)
    const [item, setItem] = useState<ColumnData2>()
    const [picklist, setPicklist] = useState<PickListItem[]>()


    let list = prioritizeCommands(ctx, props.items)
    if (term) list = filteredColumns(props.items, term).sortBy(x => x.LocalName)
    if (moreActive) list = list[list.length - 1].items!

    return <div id="TestHamburger" style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto' }} >
        <SmartInput type="text" placeholder="⌕" style={{ flexGrow: 0 }} onChange={e => {
            const trm = e.currentTarget.value//.toLowerCase()
            //const filt = filteredColumns(props.items, trm).sortBy(x => x.LocalName)
            //setState(filt)
            setTerm(trm)
        }} />
        {(list.length > 1 && moreActive) ?
            <div onClick={() => {
                setMoreActive(!moreActive)
            }} >{ctx.data.IsRtl ? '▷' : '◁'}&nbsp;{ctx.localized("Back")}</div>
            : <></>}
        {item ?
            <div onClick={() => {
                setItem(undefined)
            }} >{ctx.data.IsRtl ? '▷' : '◁'}&nbsp;{ctx.localized("Back")}</div>
            : <></>}
        <div style={{ overflowY: 'auto', display: 'flex', flexDirection: 'column', flexGrow: 1, padding: ".5vh", gap: ".5vh" }}>
            {(() => {
                if (!picklist || !item)
                    return list?.map(item => <HamburgerItem key={item.Name} command={item} onCommand={props.onCommand} onClick={async back => {
                        if (!back) {
                            if (item.HasPickList) {
                                //const pl = await MachshevetClient.CommandOptions(item.recordType!, item.Name!)
                                const gp2 = { ...props.gridProps! }
                                gp2.Member = item.Name
                                //gp2.RecordType = item.recordType
                                //const pl = await MachshevetClient.CommandOptions({ ...props.gridProps!, Member: item.Name })
                                const pl = await MachshevetClient.CommandOptions(gp2)
                                setPicklist(pl)
                                setItem(item)
                            }
                            else {
                                setMoreActive(!moreActive)
                            }
                        }
                    }} />)
                else
                    return picklist?.map(pick => {
                        const cp = { ...item! }
                        cp.commandParams = [pick.Key]
                        return <HamburgerPickItem {...pick} key={pick.Key} command={item!.Name!} onClick={() => props.onCommand ? props.onCommand(cp) : undefined} />
                    })

            })()}
        </div>
    </div>
}

export const HamburgerPickItem: FC<PickListItem & { onClick: React.MouseEventHandler, command: string }> = props => {
    return <SmartButton buttonStyle={false} testName={"_Field_" + props.command + '_' + props.Key} onClick={props.onClick} style={{ display: 'flex' }}>
        <Icon name={props.Icon || props.command} />
        <span style={{ whiteSpace: 'nowrap' }}>{props.Value}</span>
    </SmartButton>
}

export const MyTab: FC<TabType> = props => {
    return <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto' }}>{props.children}</div>
}

export const Accordion: FC<{ children: ReactElement[], closeTab?: boolean }> = props => {
    const [selectedTab, setSelectedTab] = useState<number>();
    useEffect(() => {
        setSelectedTab(undefined)
    }, [props.closeTab])
    return <div id="TestAccordion">
        {props.children.map((item, i) => {
            const tt = item.props as TabType
            return <><SmartDiv id={"TestAccordion" + item.key} key={i} onClick={() => {
                if (selectedTab === i) {
                    setSelectedTab(undefined);
                } else {
                    setSelectedTab(i)
                }
            }} >{tt.titleText}</SmartDiv>{i == selectedTab ? item : null}</>

        })}
    </div>
}

export const MyTabs: FC<{ children: ReactElement[], selectedTabChanged?: (index: number) => void, activeTab?: number }> = props => {
    const ctx = useContext(MainContext)
    // const [selectedTab, setSelectedTab] = useState(props.activeTab || 0)
    const [selectedTab, setSelectedTab] = useState(0)
    //const { ID } = useParams()

    //useEffect(() => {
    //    setSelectedTab(0)
    //}, [ID])

    useEffect(() => {
        //if (props.activeTab && props.activeTab !== selectedTab) {
        setSelectedTab(props.activeTab || 0)
        //}
    }, [props.activeTab])

    function selectTab(index: number) {
        setSelectedTab(index)
        props.selectedTabChanged && props.selectedTabChanged(index)
    }

    return <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflowY: 'auto' }}>
        <div style={{ display: 'flex', gap: 0 }}>
            {props.children.map((item, i) => {
                const tt = item.props as TabType
                const topmrg = selectedTab == i ? 0 : 4
                const bordrwdt = (selectedTab == i ? 5 : 1) + "px 1px " + (selectedTab == i ? 0 : 1) + "px 1px"
                const ttl = tt.titleText ? <span style={tt.style}>{tt.titleText}</span> : tt.titleElement
                return <SmartDiv key={i} id={"TestTab" + tt.reportID} onClick={() => {
                    if (props.activeTab === undefined) selectTab(i)
                    tt.onClick && tt.onClick()
                }} style={{ backgroundColor: '#ebebeb', padding: "1ch", borderColor: numberColor(ctx.data.PrimaryColor), borderRadius: "0px", borderWidth: bordrwdt, borderStyle: "solid", marginTop: topmrg }}>{ttl}</SmartDiv>
            })}
        </div>
        <div style={{ borderColor: numberColor(ctx.data.PrimaryColor), borderStyle: "solid", borderWidth: "1px 1px 1px 1px", overflowY: 'auto', display: 'flex', flexGrow: 1 }}>
            {
                props.children.map((x, i) => {
                    const tt = x.props as TabType
                    return <div key={i} id={"Test" + tt.testName} style={{ display: i == selectedTab ? "flex" : "none", flexDirection: 'column', flexGrow: 1, overflowX: 'auto' }}>{x}</div>
                })
            }
        </div>
    </div>
}

export const CardList: FC<TableBodyProps> = props => {
    return <div style={{ display: 'grid', gridTemplateColumns: "repeat(auto-fit, minmax(6em, 20em))", gap: "3ch", width: "100%", backgroundColor: "lightgray", padding: "3ch", overflowY: "auto" }} >{props.records?.Records.map(x => {
        const selkeys = props.gridProps?.RecordKeys
        const isSelected = selkeys && selkeys.has(x.RecordKey!)
        return <NiceCard key={x.RecordKey} record={x} isSelected={isSelected} records={props.records!} onSelected={(e, u) => {
            props.setSelected!([e], true, u)
        }} onContextMenu={e => {
            props.setSelected!([x], true, false)
            props.setMenuField!(e)
        }} />
    })}</div>
}

export const ChatList: FC<TableBodyProps> = props => {
    return <div style={{ overflowY: "auto" }}>{props.records?.Records.map(x => {
        const flds = getControlProps2(x.Fields, props.records!.Columns)
        const body = flds.sortBy(x => -x.memberData!.MaxLength!)[0]
        //const regfields = flds.filter(x => x.Name !== body.Name && x.Name !== "FileBytes")
        const regfields = flds.filter(x => x.memberData.Name !== body.memberData.Name && x.memberData.Name !== "FileBytes")
        const imgfield = flds.filter(x => x.memberData.Name === "FileBytes" && x.Value !== null)[0]
        if (imgfield) {
            imgfield.showTools = false
            imgfield.recordID = x.RecordID
        }
        const stl: CSSProperties = { display: 'flex', gap: 10 }
        stl.padding = x.Incoming ? "0px 20px 0px 0px" : "0px 0px 0px 20px"
        return <div key={x.RecordKey} style={{ padding: 15, display: 'flex' }} onContextMenu={e => {
            e.preventDefault()
            props.setSelected!([x], true, true)
            props.setMenuField!(e)
        }} >
            <div style={stl}>
                <img src={docSource("Person", x.PersonID!, "Image")} style={{ maxWidth: 60 }} />
                <div style={{ flexGrow: 1 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: 20 }}>{regfields.map(y => <div key={y.Key} onContextMenu={e => {
                        e.preventDefault()
                        e.stopPropagation()
                        props.setSelected!([x], true, true)
                        props.setMenuField!(e, y)
                    }} ><Control field={{ ...y, showTools: false }} /></div>)}</div>
                    <div style={{ backgroundColor: numberColor(x.BackColor?.Value), borderRadius: 5, padding: 10 }}>{body.Value}</div>
                    {imgfield && <div id="TestChatImg"><FilePick {...imgfield} /></div>}

                </div>
            </div>
        </div>
    })}</div>
}
export const NiceCard: FC<{ record: LiteRecordData, isSelected?: boolean, onSelected?: (record: LiteRecordData, unselectOthers: boolean) => void, onContextMenu?: (coords: React.MouseEvent) => void, records: RecordsData }> = props => {
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)!
    const [hover, setHover] = useState(false)
    const rec = props.record
    const flds = getControlProps3(rec, props.records.Columns)
    const relvfields = flds.filter(y => y.Value && y.memberData.FieldType !== FieldTypes.Bitmap).sortBy(y => y.memberData!.Position)
    const img = flds.find(y => y.memberData.FieldType === FieldTypes.Bitmap)
    const cmds = props.records.Columns && props.records.Columns.length ? props.records.Columns.filter(y => y.IsCommand).sortBy(y => y.Position) : undefined
    const gp = defaultGridProps()
    gp.RecordKeys = new Set([rec.RecordKey!])
    return <div draggable key={rec.RecordKey} style={{ backgroundColor: 'white', borderRadius: "3ch", padding: 5, alignItems: 'center', display: "flex", flexDirection: "column", justifyContent: "space-between" }} onClick={e => props.onSelected!(rec, !e.ctrlKey)} onDoubleClick={() => goToRecord(rec)} onContextMenu={e => props.onContextMenu && props.onContextMenu(e)} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onDragStart={() => props.onSelected!(rec, true)}>
        {/*   {img && <div style={{ padding: "2ch" }}><Control field={img} /></div>}*/}
        {img && <Control field={img} />}
        <div style={{ display: 'flex', gap: "1ch", flexDirection: "column", textAlign: 'center' }}>
            {relvfields.map(y => {
                y.style = { whiteSpace: "break-spaces" }
                return <Control key={y.Key} field={y} />
            })}
        </div>
        <div style={{ display: "flex", opacity: hover ? 1 : 0, padding: ".5vmax", justifyContent: 'space-around', position: 'absolute', gap: "1vmax" }}>
            {cmds && cmds.map(x => <SmartDiv key={x.Name} style={{ display: 'flex' }}><Icon name={x.Name!} onClick={() => doCommand(x as ColumnData2, 1, gp, app, ctx)} /></SmartDiv>)}
        </div>
    </div>
}


export const RecordCard: FC<{ record: LiteRecordData, isSelected?: boolean, onSelected?: (record: LiteRecordData, unselectOthers: boolean) => void, onContextMenu?: (coords: React.MouseEvent) => void, records: RecordsData }> = props => {
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)!
    const [hover, setHover] = useState(false)
    const rec = props.record
    const recname = rec.RecordName
    const flds = getControlProps3(rec, props.records.Columns)
    const relvfields = flds.filter(y => y.memberData.Name !== 'Name' && y.Value && y.memberData.FieldType !== FieldTypes.Bitmap).sortBy(y => y.memberData!.Position)
    const img = flds.find(y => y.memberData.FieldType === FieldTypes.Bitmap)
    const cmds = props.records.Columns && props.records.Columns.length ? props.records.Columns.filter(y => y.IsCommand).sortBy(y => y.Position) : undefined
    let backclr = numberColor(ctx.data.PrimaryColor)
    const stl: CSSProperties = { borderWidth: 1, borderStyle: 'solid', borderRadius: 8 }
    if (props.isSelected) {
        stl.borderWidth = 2
        backclr = "orange"
    }
    stl.borderColor = backclr
    const gp = defaultGridProps()
    gp.RecordKeys = new Set([rec.RecordKey!])
    return <div draggable key={rec.RecordKey} style={stl} onClick={e => props.onSelected!(rec, !e.ctrlKey)} onDoubleClick={() => redirect(undefined, rec.RecordType, rec.RecordID)} onContextMenu={e => props.onContextMenu && props.onContextMenu(e)} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} onDragStart={() => props.onSelected!(rec, true)}>
        <div style={{ fontWeight: 'bold', color: "white", padding: 5, backgroundColor: backclr, borderRadius: "8px 8px 0px 0px" }}>{recname}</div>
        <div id="TestGlanceCard" style={{ padding: 5, backgroundColor: "white" }}>
            <div style={{ display: 'flex', gap: "1vw" }}>
                {img && <Control field={img} />}
                <div>
                    {relvfields.map(y => <div key={y.Key} style={{ display: 'flex', gap: 3 }}>
                        <Icon name={y.memberData!.Icon!} title={y.memberData!.LocalName} />
                        <Control field={y} />
                    </div>)}
                </div>
            </div>
            <div style={{ display: "flex", opacity: hover ? 1 : 0, padding: ".5vmax", justifyContent: 'space-around', position: 'absolute', backgroundColor: "white", borderColor: backclr, borderWidth: 1, borderStyle: 'solid', borderRadius: 5, gap: "1vmax" }}>
                {cmds && cmds.map(x => <SmartDiv key={x.Name} style={{ display: 'flex' }}><Icon name={x.Name!} onClick={() => doCommand(x as ColumnData2, 1, gp, app, ctx)} /></SmartDiv>)}
            </div>
        </div>
    </div>
}

//export const Popover: FC<HTMLProps<HTMLDivElement> & { position?: [number, number], autoJump?: boolean }> = props => {
//    return <div style={{ ...props.style, position: "fixed" }} > 
//        {props.children}
//    </div>
//}

//export const Popout: FC<HTMLProps<HTMLDivElement> & { position?: [number, number], forceSetLeft?: boolean }> = props => {
export const Popout: FC<HTMLProps<HTMLDivElement> & { position?: [number, number] }> = props => {
    const [popTop, setPopTop] = useState(props.position?.[1])
    //const [popLeft, setPopLeft] = useState(props.position?.[0])
    const [popLeft, setPopLeft] = useState<number>()
    const [popHeight, setPopHeight] = useState<number>()
    const [popWidth, setPopWidth] = useState<number>()
    const myref = useRef<HTMLDivElement>(null)
    const [parent, setParent] = useState<HTMLElement>()
    //const ctx = useContext(MainContext)!
    function findPopupPosition() {
        if (parent) {
            const parentRect = parent.getBoundingClientRect()
            const top = props.position ? props.position[1] : window.pageYOffset + parentRect.top + parentRect.height
            let left = props.position ? props.position[0] : window.pageXOffset + parentRect.left
            if (left < 0) left = 0
            if (myref.current) {
                const elementRect = myref.current.getBoundingClientRect()
                const bottomRelativeToContainer = elementRect.bottom
                const bottomSpace = window.innerHeight - bottomRelativeToContainer
                if (bottomSpace < 0) {
                    setPopHeight(window.innerHeight - top)
                }
                if (elementRect.left < 0) setPopWidth(elementRect.width + elementRect.left)
                if (elementRect.right > window.innerWidth) setPopWidth(window.innerWidth - elementRect.left)
            }
            setPopTop(top)
            setPopLeft(left)
        }
    }

    useEffect(() => {
        const prnt = myref.current!.parentElement
        setParent(prnt!)
        const scrlr = findScrollableParent(prnt!)
        if (scrlr) scrlr.addEventListener('scroll', findPopupPosition)
        window.addEventListener('resize', findPopupPosition)
        findPopupPosition()

        const resizeObserver = new ResizeObserver(() => {
            findPopupPosition()
        })
        resizeObserver.observe(myref.current!)

        return () => {
            if (scrlr) scrlr.removeEventListener('scroll', findPopupPosition)
            window.removeEventListener('resize', findPopupPosition)
            if (myref.current) resizeObserver.unobserve(myref.current)
            resizeObserver.disconnect()
        }
    })

    const stl = { ...props.style }
    stl.top = popTop// && bottomSpc && bottomSpc < 0 ? popTop + bottomSpc : popTop /// ? popTop - 300 : popTop
    ////stl.bottom = 350
    stl.maxHeight = popHeight
    stl.maxWidth = popWidth
    stl.left = popLeft
    //if (!ctx.data.IsRtl || props.forceSetLeft) stl.left = popLeft;
    //stl.top = top2// ? 35 : 'unset';
    //stl.left =  top < 0 ? 120 : 0
    //stl.bottom = top ? 'unset' : 35;
    {/*return <div ref={myref} {...props} style={{ ...stl, position: "absolute", zIndex: 3 }}>*/ }
    return <div id="TestPopout" ref={myref} {...props} style={{ ...stl, position: "fixed" }}>
        {props.children}
    </div>
}

export const PrintFilter: FC<CommandFile2 & { onClose: () => void }> = props => {
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)
    const [state, setState] = useState(props)
    const flds = getControlProps2(state.FilterControls, state.FilterColumns)
    return <DialogPlus {...props} title={ctx.localized("PrintFilter")} footer={<SmartButton onClick={async () => {
        await doCommand(props.origCommand, 1, props.origGridProps, app, ctx)
    }
    }>{ctx.localized("Print")} </SmartButton>}  >
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
            {flds.map(column => {
                return <div id={"Test_Control_" + column.memberData.Name} key={column.Key} style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
                    <div>{column.memberData.LocalName}</div>
                    <Control field={{ ...column, onChange: async v => setState(prev => ({ ...prev, FilterControls: prev.FilterControls.map(x => x.Key === column.Key ? { ...x, Value: v } : x) })) }} />
                </div>
            })}
        </div>
    </DialogPlus>
}

export const ControlHolder: FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)!
    const md = props.memberData
    //const gp = new GridProps()
    //gp.SettingGroup = SettingGroup.EditFields
    const stl: CSSProperties = { ...props.style, backgroundColor: numberColor(md.BackColor), color: numberColor(md.ForeColor), fontSize: md.FontSize, opacity: props.Useful === false ? .2 : 1, flexGrow: 1 }
    if (md.Visible === false) stl.display = "none"
    return <div key={props.Key} style={stl} >
        <div style={{ display: 'flex', fontSize: "90%", justifyContent: 'space-between' }}>
            <SmartDiv id={"Test_Label_" + props.memberData.Name} style={{ display: 'inline-block' }} title={md.Tooltip || md.Definition} draggable onDragStart={e => e.dataTransfer.setData('text/plain', props.memberData.Name!)} onDragOver={e => e.preventDefault()} onContextMenu={e => ctx.showContextMenu!(e, undefined, props.items?.map(x => x as ColumnData2), props, undefined, undefined, undefined, props.gridProps)}
                onDrop={async e => {
                    const drg = e.dataTransfer.getData('text/plain')
                    if (drg) {
                        await MachshevetClient.MoveMember(props.recordType!, SettingGroup.EditFields, drg, props.memberData.Name!)
                        await props.reloader!()
                    }
                }}>{md.LocalName}</SmartDiv>

            <div id={"Test_Error_" + props.memberData.Name} style={{ color: "red" }}>
                {props.ErrorText}{props.ErrorRecordID ? <NavLink recordType={props.recordType} recordID={props.ErrorRecordID} >{' ' + props.ErrorRecordID}</NavLink> : <></>}
            </div>

            {!md.IsCommand && props.WarningText && <span style={{ color: "orange" }}>{props.WarningText}</span>}
        </div>
        <div id={"Test_Control_" + props.memberData.Name} style={{ display: 'flex' }} data-editable={md.Editable}><Control field={props} /></div>
    </div>
}

export const TopAlerts: FC = () => {
    //const alr = useContext(AlertsContext)
    const [alerts, setAlerts] = useState<AppData>()
    useEffect(() => {
        setAlerts(alertStore.getAlerts())
        const unsubscribe = alertStore.subscribe(setAlerts)
        return () => unsubscribe()
    }, [])
    const alr = alerts
    return <div style={{ display: "flex", gap: "1vw" }}>
        {alr?.Alerts.filter(x => x.Count !== 0).map(x => <NavLink key={x.ID} reportID={x.ID} title={x.LocalName} style={{ display: "flex", fontSize: "140%", alignItems: "flex-start" }}>
            <Icon name={x.RecordType!} />
            <div style={{ borderRadius: "30%", backgroundColor: "red", padding: ".15vw", fontSize: "45%", color: "white" }}>
                <SmartCount number={x.Count} fieldType={x.AggregationFieldType} />
            </div>
        </NavLink>)}
    </div>
}

export const SidePops: FC = () => {
    const ctx = useContext(MainContext)
    const [showAlerts, setShowAlerts] = useState(true)
    const [alerts, setAlerts] = useState<AppData>()
    useEffect(() => {
        setAlerts(alertStore.getAlerts())
        const unsubscribe = alertStore.subscribe(setAlerts)
        return () => unsubscribe()
    }, [])

    const alr = alerts

    const popcount = alr?.Popups.map(x => x.Records).sum(x => x.length) || 0
    let flipside = ctx.data.IsRtl
    const ns = ctx.data.NearSidePopups
    if (ns) flipside = !flipside
    return popcount > 0 ? <div style={{ position: 'absolute', bottom: 0, left: flipside ? 0 : "inherit", right: flipside ? "inherit" : 0, display: 'flex', flexDirection: 'column', zIndex: 1, overflowY: "auto", maxHeight: "40vh", backgroundColor: "transparent", minHeight: "6vh" }} >
        <div style={{ overflowY: "auto", display: "flex", flexDirection: ns ? "row-reverse" : "unset" }}>
            <SmartDiv id="Test_TogglePopups" onClick={() => setShowAlerts(!showAlerts)} style={{ alignSelf: "end", marginBottom: "10%", borderColor: "gray", borderStyle: "solid", borderWidth: 1, padding: ".5vh", borderRadius: "0px 10px 10px 0px", borderInlineEnd: "unset", backgroundColor: "white" }}>{showAlerts ? flipside ? "◀" : "▶" : flipside ? "▶" : "◀"}</SmartDiv>
            {showAlerts && <div id="Test_Popups" style={{ overflowY: "auto", display: "flex", flexDirection: "column" }}>
                {alr?.Popups.map(x => {
                    return <div key={x.ReportID} style={{ gap: 10, display: 'flex', flexDirection: 'column' }}> {
                        x.Records.map(y => <Attention record={y} data={x} key={y.RecordID} />)
                    }</div>
                })}
            </div>
            }
        </div>
    </div> : <></>
}

const MapViewSetter: FC<{ records: LiteRecordData[] }> = props => {
    const map = useMap()
    const locs = props.records
    useEffect(() => {
        if (locs.length > 0) {
            map.setView([locs[0].Latitude!, locs[0].Longitude!], 13)
        }
    }, [map])
    return null
};

export const ReportMap: FC<TableBodyProps> = props => {
    const locs = props.records!.Records.filter(x => x.Latitude)
    const custDivIcon = L.divIcon({ className: 'custom-div-icon', html: '📌', iconSize: [20, 20] })

    return <MapContainer style={{ height: '100%', width: '100%' }} >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <MapViewSetter records={locs} />
        {locs.map(x => (
            <Marker key={x.RecordID} position={[x.Latitude!, x.Longitude!]} icon={custDivIcon}>
                <Popup>
                    <RecordCard record={x} records={props.records!} />
                </Popup>
            </Marker>
        ))}
    </MapContainer>
}

export const ChartBox: FC<TableBodyProps> = props => {
    const [size, setSize] = useState({ width: 0, height: 0 });
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const updateSize = () => {
            const curcont = containerRef.current;
            if (curcont) setSize({
                width: curcont.offsetWidth,
                height: curcont.offsetHeight,
            });
        };
        updateSize();
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    const viewb = `0 0 ${size.width} ${size.height}`
    const renderChart = () => {
        switch (props.gridProps?.DisplayType) {
            case ReportTypes.LineChart:
                return <LineChart data={props.records!.ChartData} size={size} />
            case ReportTypes.PieChart:
                return <PieChart data={props.records!.ChartData!} size={size} showTools={props.showTools} />
            default:
                return null;
        }
    };

    return <div ref={containerRef} style={{ width: "100%" }}>
        <svg viewBox={viewb} width={size.width || '100%'} style={{ height: "100%" }}>
            {renderChart()}
        </svg>
    </div>

}

function niceNumber(value: number, round: boolean) {
    const exponent = Math.floor(Math.log10(value)); // Get the exponent of the value
    const fraction = value / Math.pow(10, exponent); // Normalize the value
    let niceFraction;

    if (round) {
        if (fraction < 1.5) niceFraction = 1
        else if (fraction < 3) niceFraction = 2
        else if (fraction < 7) niceFraction = 5
        else niceFraction = 10
    } else {
        if (fraction <= 1) niceFraction = 1
        else if (fraction <= 2) niceFraction = 2
        else if (fraction <= 5) niceFraction = 5
        else niceFraction = 10
    }

    return niceFraction * Math.pow(10, exponent);
};

function calculateTicks(min: number, max: number, numTicks: number) {
    const range = niceNumber(max - min, false); // Nice range covering min and max
    const tickSpacing = niceNumber(range / (numTicks - 1), true); // Nice tick spacing
    const niceMin = Math.floor(min / tickSpacing) * tickSpacing; // Adjusted minimum
    const niceMax = Math.ceil(max / tickSpacing) * tickSpacing; // Adjusted maximum

    const ticks = [];
    let tick = niceMin;
    while (tick <= niceMax && ticks.length < numTicks) {
        ticks.push(tick);
        tick += tickSpacing;
    }
    return ticks;
};

export const LineChart: FC<{ data: ChartPoint[], size: { width: number, height: number } }> = props => {
    const ctx = useContext(MainContext)
    const padding = 4
    const data = props.data || []
    const yMin = 0
    const yMax = Math.max(...data.map(r => r.Value))
    const sz = props.size
    const fontSize = Math.min(sz.width, sz.height) * 0.03
    function xScale(input: ChartPoint) {
        const availableWidth = sz.width - (padding * 2);
        const indexRatio = data.indexOf(input) / (data.length - 1);
        return ctx.data.IsRtl
            ? sz.width - padding - indexRatio * availableWidth // Convert to pixels for RTL
            : padding + indexRatio * availableWidth;
    }
    function yScale(count: number) {
        return sz.height - padding - ((count - yMin) / (yMax - yMin)) * (sz.height - 2 * padding)
    }
    const linePath = data.map((record, index) => `${index === 0 ? 'M' : 'L'} ${xScale(record)} ${yScale(record.Value)}`).join(' ')
    const yTicks = calculateTicks(yMin, yMax, 7)

    return <> <line x1={padding} y1={sz.height} x2={sz.width} y2={sz.height} stroke="#ccc" strokeWidth="0.5" />
        <line x1={ctx.data.IsRtl ? sz.width - padding : padding} y1={padding} x2={ctx.data.IsRtl ? sz.width - padding : padding} y2={sz.height - padding} stroke="#ccc" strokeWidth="0.5" />
        <path d={linePath} stroke={numberColor(ctx.data.PrimaryColor)} strokeWidth={2} style={{ fill: "none" }} />
        {data.map(x => {
            const cx = xScale(x)
            return <g key={x.Key}>
                <circle cx={cx} cy={yScale(x.Value)} r="4" fill={numberColor(ctx.data.SecondaryColor)} stroke={numberColor(ctx.data.SecondaryColor)} />
                <title>{x.Label + ': ' + x.Value}</title>
                <text key={x.Label} x={cx} y={sz.height} textAnchor="middle" style={{ fontSize: fontSize }}>
                    {x.Label}
                </text>
            </g>
        })}
        {
            yTicks.map(x => <g key={x}>
                <line x1={ctx.data.IsRtl ? 100 - padding : padding} y1={yScale(x)} x2={ctx.data.IsRtl ? 100 - padding - 3 : padding + 3} y2={yScale(x)} stroke="#ccc" strokeWidth="0.5" />
                <text x={ctx.data.IsRtl ? 100 - padding : padding} y={yScale(x)} style={{ fontSize: fontSize }}>{x}</text>
            </g>)
        }
    </>
}

function getPieChartColor(slices: number, index: number) {
    const hue = (index * 360 / slices) % 360
    return `hsl(${hue}, 70%, 50%)`
}

//const PieSlice: FC<{ point: ChartPoint, total:number }> = props => {
//    const percentage = props.point.Value / props.total
//    const angle = percentage * 2 * Math.PI
//    const startX = center.x + radius * Math.cos(currentAngle)
//    const startY = center.y + radius * Math.sin(currentAngle)
//    const endX = center.x + radius * Math.cos(currentAngle + angle)
//    const endY = center.y + radius * Math.sin(currentAngle + angle)
//    const largeArcFlag = angle > Math.PI ? 1 : 0
//    const pathData = [`M ${center.x} ${center.y}`, `L ${startX} ${startY}`, `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`, 'Z'].join(' ')

//    const labelX = center.x + (radius * 0.7) * Math.cos(currentAngle + angle / 2)
//    const labelY = center.y + (radius * 0.7) * Math.sin(currentAngle + angle / 2)

//    const clr = getPieChartColor(dat.length, index)
//    currentAngle += angle
//    const lbl = item.Label + ': ' + (percentage * 100).toFixed(1) + '% (' + item.Value + ')'
//    return <g key={index}>
//        <path d={pathData} style={{ fill: clr }} stroke="white" strokeWidth="1" />
//        {props.showTools ? <text x={labelX} y={labelY} textAnchor="middle" dominantBaseline="middle" fill="#333" fontSize="smaller">{lbl}</text> : <></>}
//        {/*<foreignObject x={labelX - 15} y={labelY - 10} width="1" height="1">*/}
//        {/*    <div style={{ display: 'inline-block', overflow: 'visible' }}>*/}
//        {/*        {item.Label}-{item.Value}*/}
//        {/*        <SmartCount number={percentage} fieldType={FieldTypes.Percent} />*/}
//        {/*    </div>*/}
//        {/*</foreignObject>*/}

//        <title>{lbl}</title>
//    </g >
//}

export const PieChart: FC<{ data: ChartPoint[], size: { width: number, height: number }, showTools: boolean }> = props => {
    const sz = props.size
    const radius = Math.min(sz.width, sz.height) / 2 - 20
    const center = { x: sz.width / 2, y: sz.height / 2 }
    const dat = props.data || []
    const total = dat.reduce((sum, item) => sum + item.Value, 0)
    let currentAngle = 0

    return <>{dat.map((item, index) => {
        const percentage = item.Value / total
        const angle = percentage * 2 * Math.PI
        const startX = center.x + radius * Math.cos(currentAngle)
        const startY = center.y + radius * Math.sin(currentAngle)
        const endX = center.x + radius * Math.cos(currentAngle + angle)
        const endY = center.y + radius * Math.sin(currentAngle + angle)
        const largeArcFlag = angle > Math.PI ? 1 : 0
        const pathData = [`M ${center.x} ${center.y}`, `L ${startX} ${startY}`, `A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}`, 'Z'].join(' ')

        const labelX = center.x + (radius * 0.7) * Math.cos(currentAngle + angle / 2)
        const labelY = center.y + (radius * 0.7) * Math.sin(currentAngle + angle / 2)

        const clr = getPieChartColor(dat.length, index)
        currentAngle += angle
        const lbl = item.Label + ': ' + (percentage * 100).toFixed(1) + '% (' + item.Value + ')'
        return <g key={index}>
            <path d={pathData} style={{ fill: clr }} stroke="white" strokeWidth="1" />
            {props.showTools ? <text x={labelX} y={labelY} textAnchor="middle" dominantBaseline="middle" fill="#333" fontSize="smaller">{lbl}</text> : <></>}
            {/*<foreignObject x={labelX - 15} y={labelY - 10} width="1" height="1">*/}
            {/*    <div style={{ display: 'inline-block', overflow: 'visible' }}>*/}
            {/*        {item.Label}-{item.Value}*/}
            {/*        <SmartCount number={percentage} fieldType={FieldTypes.Percent} />*/}
            {/*    </div>*/}
            {/*</foreignObject>*/}

            <title>{lbl}</title>
        </g >
    })
    }</>
}

export const Chatbot: FC = () => {
    const [iframeUrl, setIframeUrl] = useState("")

    useEffect2(async () => {
        const url = await MachshevetClient.GetChatbotUrl()
        setIframeUrl(url)
    }, [])

    return <iframe className="mx-auto" style={{ height: 640, width: 400, }} src={iframeUrl}
    ></iframe>
}