//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React from "react"
import { FC, useContext, useState } from "react"
import { ColumnData, DataTypes, EntityData, FilterTypes, GridProps, Intervals, MachshevetClient, PickListItem, ReportTypes } from "./Declarations"
import { enumList } from "./globals"
import { ListView } from "./ListView"
import { useEffect2 } from "./misc"
import { SelectPlus } from "./pickers"
import { MainContext } from "./styles"

export const ReportCenter: FC = () => {
    const ctx = useContext(MainContext)
    const [recordTypes, setRecordTypes] = useState<EntityData[]>()
    const [fields, setFields] = useState<ColumnData[]>()
    const [gridProps, setGridProps] = useState<GridProps>(new GridProps())
    //const [filters, setFilters] = useState<{ field: string, type: FilterTypes, value?: any, options?: PickListItem[], }[]>([])
    const [filters, setFilters] = useState<{ field: string, options?: PickListItem[], }[]>([])

    useEffect2(async () => {
        const typs = await MachshevetClient.RecordTypes()
        setRecordTypes(typs)
    }, [])

    useEffect2(async () => {
        if (gridProps.RecordType) {
            const flds = await MachshevetClient.ReportFields(gridProps.RecordType)
            setFields(flds)
        }
    }, [gridProps.RecordType])


    useEffect2(async () => {
        filters.forEach(async x => {
            //if (!x.options) {
            const gfilt = gridProps.Filters.find(y => y.Name === x.field)
            const opts = await MachshevetClient.FieldCount(gridProps, x.field, undefined, gfilt?.FilterType)
            const pickitems = opts.map(x => {
                const pli = new PickListItem
                pli.Key = x.Key
                pli.Value = x.Value + ' (' + x.Count + ')'
                return pli
            })
            setFilters(prev => prev.map(y => y.field === x.field ? { ...y, options: pickitems } : y))
            //}
        })

    }, [gridProps])

    const flds = fields?.sortBy(x => x.LocalName).map(x => {
        const pli = new PickListItem()
        pli.Icon = x.Icon
        pli.Key = x.Name
        pli.Value = x.LocalName
        return pli
    })

    const valflds = fields?.filter(x => x.DataType === DataTypes.Number).sortBy(x => x.LocalName).map(x => {
        const pli = new PickListItem()
        pli.Icon = x.Icon
        pli.Key = x.Name
        pli.Value = x.LocalName
        return pli
    })

    const typs = enumList(ReportTypes, ctx).map(x => {
        const pli = new PickListItem()
        pli.Icon = x.Value
        pli.Key = x.Key
        pli.Value = ctx.localized(x.Value)
        return pli
    }).sortBy(x => x.Value)

    //const intvs = enumList(Intervals).map(x => {
    //    const pli = new PickListItem()
    //    pli.Key = x.Key
    //    pli.Value = ctx.localized(x.Value)
    //    return pli
    //}).sortBy(x => x.Key)

    return <div style={{ display: "flex", overflowY: 'auto', flexGrow: 1 }}>
        {recordTypes && <div style={{ display: "flex", flexDirection: 'column', fontSize: "90%" }}>
            {ctx.localized("Source")}
            <SelectPlus options={recordTypes?.sortBy(x => [-x.Rows, x.Title])} value={gridProps.RecordType} keyField="RecordType" valueField="Title" iconField="RecordType" onChange={v => {
                setGridProps(prev => ({ ...prev, RecordType: v ? '' + v : undefined }))
            }} />
            {ctx.localized("Type")}
            <SelectPlus options={typs} value={gridProps.DisplayType} onChange={e => {
                setGridProps(prev => ({ ...prev, DisplayType: e as number }))
            }} />
            {ctx.localized("LabelField")}
            {flds && <SelectPlus options={flds} value={gridProps.LabelField} onChange={e => {
                setGridProps(prev => ({ ...prev, LabelField: '' + e }))
            }} />}
            {ctx.localized("LabelInterval")}
            {flds && <SelectPlus options={enumList(Intervals, ctx, true)} value={gridProps.LabelInterval} onChange={e => {
                setGridProps(prev => ({ ...prev, LabelInterval: e as number }))
            }} />}
            {ctx.localized("ValueField")}
            {valflds && <SelectPlus options={valflds} value={gridProps.ValueField} onChange={e => {
                setGridProps(prev => ({ ...prev, ValueField: '' + e }))
            }} />}
            {ctx.localized("Filters")}
            <div style={{ display: "flex", gap: "1ch", flexDirection: "column" }}>
                {filters.map(x => {
                    const gpf = gridProps.Filters.find(y => y.Name === x.field)
                    return <div key={x.field} style={{ display: 'flex', justifyContent: "space-between", gap: "2ch" }}>
                        <span>{ctx.localized(x.field)}</span>
                        <SelectPlus options={enumList(FilterTypes, ctx, true)} value={gpf?.FilterType} onChange={e => {
                            setGridProps(prev => {
                                let newFilters = prev.Filters
                                if (!newFilters.some(filter => filter.Name === x.field)) {
                                    newFilters.push({ ...new ColumnData(), Name: x.field })
                                }
                                newFilters = newFilters.map(y => y.Name === x.field ? { ...y, FilterType: e } : y)
                                return { ...prev, Filters: newFilters }
                            })
                        }} />
                        <SelectPlus options={x.options || []} value={gpf?.IDFilter} onChange={e => {
                            setGridProps(prev => {
                                let newFilters = prev.Filters
                                if (!newFilters.some(filter => filter.Name === x.field)) {
                                    newFilters.push({ ...new ColumnData(), Name: x.field })
                                }
                                newFilters = newFilters.map(y => {
                                    if (y.Name === x.field) {
                                        //if (y.FilterType === FilterTypes.InYear) {
                                        //    y.FilterList = JSON.stringify([e])
                                        //} else {
                                        y.IDFilter = e
                                        y.FilterList = JSON.stringify([e])
                                        // }
                                    }
                                    return y
                                })
                                return { ...prev, Filters: newFilters }
                            })
                        }} />

                    </div>
                })
                }
            </div>
            {fields && <SelectPlus options={fields} keyField="Name" valueField="LocalName" onChange={e => {
                setFilters(prev => [...prev, { field: '' + e }])
            }} />}
        </div>}
        {gridProps.RecordType && <ListView gridProps={gridProps} showTools={true} />}
    </div>
}