//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import { createContext, CSSProperties } from "react"
import { AppData, ChartPoint, ClipboardData, ColumnData, CommandFile, CommandList, CommandMessage, ControlProps, FileActions, GridProps, GridSums, InputData, LiteRecordData, MachshevetClient, RecordsData, SettingGroup } from "./Declarations"
import { downloadByteString, headerItem, MainAppType, RecordFormProps, reloadPage, showConfirm } from "./globals"

export class ColumnData2 extends ColumnData {
    items?: ColumnData2[]
    showTools = true
    reloader?: () => Promise<void>
    commandParams?: string[]
    style?: CSSProperties
    recordID?: number
    expanded?: boolean
    modelGetter?: () => any
    recordType?: string
}
export class ControlProps2 extends ControlProps {
    onChange?: (value?: any, field?: ControlProps2, newrow?: LiteRecordData, idx?: number) => Promise<void>
    onBlur?: (value?: any) => void
    placeholder?: string
    mainRecordType?: string
    mainCommand?: string
    items?: ColumnData[]
    expanded?: boolean
    recordID?: number
    style?: CSSProperties
    recordKey?: string
    reloader?: () => Promise<void>
    showChanges?: boolean
    showTools = false
    commandParams?: string[]
    valueTip?: string
    memberData: ColumnData = new ColumnData()
    editPage?: boolean
    recordType?: string
    gridProps?: GridProps
    editMode?: boolean
}
export interface CommandFile2 extends CommandFile {
    origCommand: ColumnData2
    origGridProps: GridProps
}
export interface CommandInputProps {
    command: ColumnData2;
    colSetter?: (input: ColumnData) => void;
    selectedKeys: Set<string>;
    mainRecordType?: string;
    data: InputData
}
const dt = new AppData()
export const MainContext = createContext<MainAppType>({ data: dt, localized: () => "", setUser: () => { }, docActive: () => true })
//export const AlertsContext = createContext<AppData | undefined>(undefined)
//export const alertStore = useAlertStore()
export const alertStore = (() => {
    let alertState: AppData | undefined;

    return {
        getAlerts: () => alertState,
        setAlerts: (newState: AppData) => {
            alertState = newState;
            // Optional: Notify subscribers
            notifySubscribers(newState);
        },
        subscribe: (callback: (state: AppData) => void) => {
            // Add a subscription mechanism if you want components to react to changes
            subscribers.push(callback);
            return () => {
                subscribers = subscribers.filter(cb => cb !== callback);
            };
        }
    };
})();

let subscribers: ((state: AppData) => void)[] = [];

function notifySubscribers(newState: AppData) {
    subscribers.forEach(callback => callback(newState));
}


export function columnControlProps2(columns: ColumnData[]) {
    return columns.map(x => {
        //const y = defaultControlProps()
        const y = new ControlProps2()
        y.memberData = x
        const m = { ...y, ...x }//this should eventually be removed propbably
        return m
    })
}
export async function handleCommandResult(input: { Key: string, Value: any }, command: ColumnData2, gridProps: GridProps, context: AppContextType, ctx: MainAppType, colSetter?: (state: ColumnData) => void) {
    if (input && input.Key) {
        const res = input
        const obj = res.Value
        const objtyp = res.Key
        if (objtyp === "CommandFile") {
            const cf: CommandFile2 = obj
            if (cf.Action == FileActions.Print) {
                if (cf.FilterColumns) {
                    cf.origCommand = command
                    cf.origGridProps = gridProps
                    ctx.showPrintFilter!(cf)
                }
                else {
                    const reader = new FileReader()
                    const byteCharacters = atob(cf.Bytes!)
                    const byteNumbers = new Array(byteCharacters.length)
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers)
                    const blb = new Blob([byteArray], { type: 'application/octet-stream' })
                    reader.readAsText(blb)
                    reader.onload = () => ctx.printHtml!(reader.result!.toString())
                }

            }
            else downloadByteString(cf.Bytes!, cf.Name! + '.' + cf.Type)
        }
        else if (objtyp === "InputData") {
            //const cip: CommandInputProps = { command: { ...command, showTools: true }, selectedKeys: gridProps.RecordKeys, mainRecordType: mainRecordType, colSetter: colSetter, data: obj }
            const cip: CommandInputProps = { command: { ...command, showTools: true }, selectedKeys: gridProps.RecordKeys, mainRecordType: gridProps.RecordType, colSetter: colSetter, data: obj }
            context.openDialog!(cip)
        }
        else if (objtyp === 'ClipboardData') {
            const cd: ClipboardData = obj
            const clipitms: ClipboardItem[] = []
            if (cd.Text) {
                const type = "text/plain";
                const blob = new Blob([cd.Text], { type });
                const data = new ClipboardItem({ [type]: blob })
                clipitms.push(data)
            }
            if (cd.Html) {
                const type = "text/html";
                const blob = new Blob([cd.Html], { type });
                const data = new ClipboardItem({ [type]: blob })
                clipitms.push(data)
            }
            await navigator.clipboard.write(clipitms)
        } else if (objtyp === 'Uri') {
            window.open(obj, "_blank")
        } else if (objtyp === 'String') {
            ctx.showToast!(obj)
        } else if (objtyp === 'Boolean') {
            const txt = obj ? "✓" : "X"
            ctx.showToast!(txt)
        } else if (objtyp === 'CommandMessage') {
            context.openMessage!(obj)
        } else if (objtyp === 'ColumnChoice') {
            ctx.openChooser!(obj, command.reloader)
        } else if (objtyp === 'CommandList') {
            const gp = new GridProps()
            const cmlist: CommandList = obj
            gp.RecordType = cmlist.RecordType
            gp.Filters = cmlist.Filters
            ctx.openTable!(gp)
        } else if (objtyp === 'ColumnData') {
            colSetter!(obj)
        } else if (objtyp === 'RecordsData') {
            const rfp: RecordFormProps = {}
            rfp.record = obj
            rfp.onSaved = command.reloader
            ctx.openRecord!(rfp)
        }
    } else {
        if (command.reloader) command.reloader()
        else reloadPage()
    }
}

export async function doCommand(command: ColumnData2, TotalRowCount: number, gridProps: GridProps, context: AppContextType, ctx: MainAppType, colSetter?: (state: ColumnData) => void, printFilters?: any) {
    if (command.Warning) {
        let recordCount = gridProps.RecordKeys?.size
        if (!recordCount) recordCount = TotalRowCount
        const warning = command.Warning.replace("{#}", recordCount.toString())
        if (!showConfirm(warning)) return
    }
    const prms = command.commandParams
    const fld = context?.menuField
    const fldnam = fld?.memberData?.Name || context?.menuColumn?.Name
    if (!gridProps.RecordType) gridProps.RecordType = context.menuField?.recordType
    const rsp = await MachshevetClient.DoCommandMulti(command.Name!, gridProps, fldnam, fld?.Value, fld?.memberData?.ReportFieldID, prms, printFilters)
    await handleCommandResult(rsp, command, gridProps, context!, ctx, colSetter)
}
export function prioritizeCommands(ctx: MainAppType, cmnds: ColumnData[]) {
    const flt = cmnds.filter(x => x.Uses > 0).sortBy(x => x.LocalName).map(x => x as ColumnData2)
    const mor = new ColumnData2()
    mor.Name = "More"
    mor.LocalName = ctx.localized("More")
    mor.items = cmnds.filter(x => x.Uses == 0).sort((one, two) => {
        return one.LocalName! < two.LocalName! ? -1 : 1
    }).map(x => x as ColumnData2)
    return [...flt, mor];
}
export interface MenuProps {
    onCommand: (command: ColumnData2) => void
    items: ColumnData2[]
    hoverable?: boolean
    gridProps?: GridProps
}
export type AppContextType = {
    openReportRecord?: (reportID: number, recordID?: number) => void;
    closeRecord: () => void;
    openMessage?: (input: CommandMessage) => void;
    openDialog?: (result: CommandInputProps) => void;
    menuField?: ControlProps2;
    menuColumn?: ColumnData
};
export async function openFieldSetting(recordType: string, fieldName: string, ctx: MainAppType) {
    const rd = await MachshevetClient.GetFieldSetting(recordType, fieldName)
    const rfp: RecordFormProps = {}
    rfp.record = rd
    ctx.openRecord!(rfp)
}
export const AppContext = createContext<AppContextType | undefined>(undefined)
export interface ContextMenuProps extends MenuProps {
    position?: [number, number];
    quickAdds?: { Key: string, Value: string }[]
}
export interface TableBodyProps {
    onRowClicked?: (id: number, name: string) => void
    onRowDoubleClick?: (record: LiteRecordData) => void
    onResultsFetched?: (header: headerItem) => void
    fullPage?: boolean
    gridProps?: GridProps
    autoRefresh?: boolean
    setSelected?: (records: LiteRecordData[], select: boolean, unselectOthers: boolean) => void
    idxSelected?: number
    handleSelection?: (id: number, name: string) => void
    handleSelections?: (keys: Set<string>) => void
    resetSelected?: (count: number) => void
    saveState?: boolean
    showTools: boolean
    records?: RecordsData
    timeDiff?: number
    onPropsChanged?: (props: GridProps) => void
    commands?: ColumnData[]
    doCommand?: (command: ColumnData2, field: ControlProps2) => void
    refreshData?: () => Promise<void>
    setMenuField?: (e: React.MouseEvent, field?: ControlProps2) => void
    loadMore?: () => Promise<void>
    totals?: GridSums
    field?: ControlProps2
    oldKeys?: string[]
    lastRefresh?: number
    autoRecordType?: boolean
    isActiveTab?: boolean
}
export class menuField {
    command?: (input: ColumnData2) => void
    colSetter?: (input: ColumnData) => void
    reloader?: () => Promise<void>
}
