//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { useState, FC, useContext, CSSProperties, useEffect } from 'react'
import { Icon, MyTab, MyTabs, NavLink, ProgressBar, SmartDiv } from './misc'
import { Options } from './Options'
import { AppData, MachshevetClient } from './Declarations'
import { getGlobalUrl, numberColor } from './globals'
import { alertStore, MainContext } from './styles'

export const SideNav: FC = () => {
    const ctx = useContext(MainContext)
    const [visible, setVisible] = useState(true)
    const [isOptions, setIsOptions] = useState(false)
    const sideTabsStyle: CSSProperties = { backgroundColor: 'transparent', fontWeight: 'bold', borderColor: 'red' }

    const [alerts, setAlerts] = useState<AppData>()
    useEffect(() => {
        setAlerts(alertStore.getAlerts())
        const unsubscribe = alertStore.subscribe(setAlerts)
        return () => unsubscribe()
    }, [])
    const alr = alerts

    return <>
        {visible ?
            <>
                <aside style={{ color: 'white', display: 'flex', padding: 10, flexDirection: 'column', backgroundColor: numberColor(ctx.data.PrimaryColor), justifyContent: 'end' }}>
                    {
                        ctx.data.UserID &&
                        <div style={{ display: 'flex', flexDirection: 'column', overflowX: 'auto', flexGrow: 1 }}>
                            <Icon name='Cancel' onClick={() => setVisible(false)} />
                            <div style={{ padding: 4 }}>
                                <span style={{ fontWeight: 'bold' }}>{ctx.localized('Add').toUpperCase()}</span>
                            </div>
                            <div style={{ whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center' }}>
                                {ctx.data.QuickAdds.map(x => <NavLink key={x.Key} title={x.Value} recordType={x.Key} action='Edit' style={{ padding: 3 }} > <SmartDiv><Icon name={x.Key} /></SmartDiv></NavLink>)}
                            </div>

                            <MyTabs>
                                <MyTab titleText={ctx.localized('Lists')} key='1' style={sideTabsStyle}>
                                    {ctx.data?.Lists && ctx.data.Lists.map((x, i) => <SideLink recordType={x.RecordType} title={x.DisplayName!} key={x.RecordType} isLast={i === (ctx.data?.Lists.length || 0) - 1} />)}
                                </MyTab>
                                <MyTab titleText={ctx.localized('History')} key='2' style={sideTabsStyle} >
                                    {ctx.data?.Recents && ctx.data?.Recents.map((x, i) => <SideLink recordType={x.RecordType} title={x.DisplayName!} recordID={x.RecordID} key={x.RecordType + '.' + x.RecordID} isLast={i === (ctx.data?.Recents.length || 0) - 1} />)}
                                </MyTab>
                                <MyTab titleText={ctx.localized('Reports')} key='3' style={sideTabsStyle}>
                                    {alr?.Populars && alr.Populars.map((x, i) => <SideLink reportID={x.ID} title={x.LocalName!} key={x.ID} isLast={i === (alr.Populars.length || 0) - 1} />)}
                                </MyTab>
                            </MyTabs>
                            <div style={{ display: 'flex', justifyContent: 'center', gap: 5 }}>
                                <NavLink action="Portal" ><Icon name='Portal' title={ctx.localized('Portal')} /></NavLink>
                                {/*<a href={getGlobalUrl('DownloadData')}><Icon name='DownloadData' title={ctx.localized('DownloadData')} /></a>*/}
                                {ctx.data.IsSysAdmin && <a href={getGlobalUrl('DownloadData')}><Icon name='DownloadData' title={ctx.localized('DownloadData')} /></a>}
                                <a href='https://docs.google.com/document/d/1bNYZlB8WvGrhxnLoSTisFBHNlvYzt2v6OAMa8fHkwQw'>
                                    <Icon name='UserGuide' title={ctx.localized('UserGuide')} />
                                </a>
                                {ctx.data.IsSandbox && <Icon name='Sandbox' title={ctx.localized('Sandbox')} />}
                                    {ctx.data.IsSysAdmin && <Icon name='Options' onClick={() => {
                                        ctx.devLog!("options clicked")
                                        setIsOptions(true)
                                    }} title={ctx.localized('Options')} />}
                                <Icon name='ClearStorage' title={ctx.localized('ClearStorage')} onClick={async () => {
                                    localStorage.clear()
                                    sessionStorage.clear()
                                    await MachshevetClient.ClearCache()
                                    window.location.reload()
                                }} />
                                <NavLink action="ReportCenter" ><Icon name='Report' title={ctx.localized('ReportCenter')} /></NavLink>
                            </div>
                            <div>{ctx.data.Progresses?.map(x => {
                                return <NavLink key={x.ID} recordType="Execution" recordID={x.ID}>
                                    <ProgressBar percent={x.Progress} title={x.LocalCommand} />
                                </NavLink>
                            })}
                            </div>
                        </div>
                    }


                    {ctx.data && <>
                        <div style={{ textAlign: 'center', paddingTop: 6 }}>{ctx.data.AppName + ' ' + ctx.localized("By")}</div>
                        <a href='https://Machshevet.com' style={{ textDecoration: "none" }}>
                            <img src={getGlobalUrl('MachshevetLogo')} style={{ width: 25 }} />
                            <span>Machshevet.com</span>
                        </a>
                    </>}

                </aside>
                {isOptions && <Options onCancel={() => setIsOptions(false)} />}
            </>
            : <div style={{ width: 'auto', backgroundColor: numberColor(ctx.data.PrimaryColor), color: 'white' }}>
                <Icon name='Add' onClick={() => setVisible(true)} />
            </div>}
    </>
}

export const SideLink: FC<{ reportID?: number, recordType?: string, recordID?: number, title: string, isLast: boolean }> = props => {
    return <NavLink {...props}>
        <SmartDiv style={{ padding: '.4vmax', borderBottom: props.isLast ? "0" : "solid .1px rgb(0 0 0 / 20%)", gap: "1ch", display: 'flex', alignItems: "center" }}>
            {props.recordType && <Icon name={props.recordType} />}
            <span id={"TestLink" + props.recordType}>{props.title}</span>
        </SmartDiv>
    </NavLink>
}

