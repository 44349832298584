//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { CSSProperties, FC, useContext } from "react"
import { getControlProps2, GetDayName, GetMonthName, getWeekNumber, goToRecord, isSameDay, monthFirstDay, numberColor, parseDate, range, RecordFormProps, rightCut, selectedColor, weekFirstDay } from "./globals"
import { NavLink, SmartButton, SmartDiv, SmartSelect } from "./misc"
import { MainContext, TableBodyProps } from "./styles"
import { RecordsData } from "./Declarations"

export const Calendar: FC<TableBodyProps> = props => {
    const ctx = useContext(MainContext)
    const StartTime = 8
    const EndTime = 19
    const fd = props.gridProps?.CalendarFromDate || new Date()
    if (fd.toString().includes("Z")) {
        const a = 1
    }
    const gp = props.gridProps!
    const records = props.records

    function setProps(newDate?: Date, weekView?: boolean) {
        const newstt = props.gridProps!
        if (newDate) newstt.CalendarFromDate = newDate
        if (weekView !== undefined) newstt.CalendarWeekView = weekView
        props.onPropsChanged!(newstt)
    }

    const next = () => {
        if (gp?.CalendarWeekView)
            setProps(new Date(fd.getFullYear(), fd.getMonth(), fd.getDate() + 7));
        else {
            const dt = new Date(fd.getFullYear(), fd.getMonth() + 1, fd.getDate());
            setProps(dt)
        }
    }
    const weekview = gp?.CalendarWeekView

    return <div id="divcal1" style={{ width: "100%", overflowY: "auto", display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
            <div>
                <SmartButton tabIndex={-1} onClick={() => {
                    if (gp.CalendarWeekView)
                        setProps(new Date(fd.getFullYear(), fd.getMonth(), fd.getDate() - 7));
                    else
                        setProps(new Date(fd.getFullYear(), fd.getMonth() - 1, fd.getDate()));
                }} >{ctx.data.IsRtl ? ">" : "<"}</SmartButton>
                <SmartSelect value={weekview ? getWeekNumber(fd) : fd.getMonth()} onChange={weekview ? e => setProps(new Date(fd.getFullYear(), 1, +e.currentTarget.value * 7)) : e => setProps(new Date(fd.getFullYear(), +e.currentTarget.value, 1))}>
                    {weekview ?
                        range(0, 52).map(week => {
                            const sunday = new Date(fd.getFullYear(), 0, week * 7);
                            const saturday = new Date(sunday.getFullYear(), sunday.getMonth(), sunday.getDate() + 6);
                            return <option value={week} key={week}>
                                {week + 1} | {sunday.getDate()}/{sunday.getMonth() + 1}-{saturday.getDate()}/{saturday.getMonth() + 1}
                            </option>
                        }) :
                        range(0, 11).map(x => {
                            const txt = (x + 1) + '|' + GetMonthName(ctx, x)
                            return <option key={x} value={x}>{txt}</option>
                        })
                    }
                </SmartSelect>
                <SmartSelect onChange={e => setProps(new Date(+e.currentTarget.value, fd.getMonth(), fd.getDate()))} value={fd.getFullYear()}>
                    {range(fd.getFullYear() - 10, fd.getFullYear() + 10).map(index => <option value={index} key={index}>{index}</option>)}
                </SmartSelect>
                <SmartButton tabIndex={-1} onClick={next} >{ctx.data.IsRtl ? "<" : ">"}</SmartButton>
            </div>

            <SmartButton onClick={() => setProps(gp?.CalendarWeekView ? weekFirstDay(new Date()) : new Date())} >{ctx.localized("Today")}</SmartButton>
            <div style={{ display: "flex", gap: "1vw" }} onClick={() => {
                const newdt = weekview ? monthFirstDay(new Date()) : weekFirstDay(new Date())
                setProps(newdt, !weekview)
            }}>
                <SmartDiv style={{ fontWeight: weekview ? "bold" : "unset" }}>{ctx.localized("Weekly")}</SmartDiv>
                <SmartDiv style={{ fontWeight: weekview ? "unset" : "bold" }}>{ctx.localized("Monthly")}</SmartDiv>
            </div>
        </div>
        <table cellSpacing="0" style={{ width: "100%", height: "100%" }}>
            {weekview ?
                <>
                    <thead>
                        <tr>
                            <th style={{ width: '2em' }}></th>
                            {range(0, 6).map(index => {
                                const day = new Date(fd.getFullYear(), fd.getMonth(), fd.getDate() + index);
                                return <th key={index}>
                                    <div>
                                        <div>{day.toDateString()}</div>
                                    </div>
                                </th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <>
                            {range(StartTime, EndTime).map(hour => {
                                const hourRecs = records!.Records.filter(x => {
                                    const fd = x.FromDate!
                                    const hr = new Date(fd).getHours();
                                    return hr >= hour && hr < (hour + 1)
                                })
                                if (hour === StartTime) {
                                    hourRecs.push(...records!.Records.filter(rec => {
                                        const fd = rec.FromDate!
                                        return (new Date(fd).getHours() < hour)
                                    }))// record befor start time go to begining
                                }
                                if (hour === EndTime) {
                                    hourRecs.push(...records!.Records.filter(rec => {
                                        const fd = rec.FromDate!
                                        return (new Date(fd).getHours() > hour)
                                    }))// record befor start time go to begining
                                }
                                return <tr style={{ verticalAlign: "top" }} key={hour}>
                                    <td style={{ textAlign: 'center', fontWeight: 700 }} >{hour}</td>
                                    {range(0, 6).map(i => {
                                        const day = new Date(fd.getFullYear(), fd.getMonth(), fd.getDate() + i);
                                        const recs = hourRecs?.filter(x => {
                                            //const fd = x.FromDate// x.Fields.filter(y => y.Key?.startsWith("FromDate"))[0].Value
                                            const fd = parseDate('' + x.FromDate)
                                            //return isSameDay(new Date(fd), day)
                                            return isSameDay(fd!, day)
                                        })
                                        recs?.sortBy(x => x.Fields.filter(y => y.Key?.startsWith("FromDate"))[0].Value)
                                        const istoday = isSameDay(day, new Date())
                                        return <td style={{ borderColor: numberColor(ctx.data.SecondaryColor), borderWidth: "0px .5px", borderStyle: "solid" }} key={i}>
                                            {recs.map(Hrec => {
                                                return <div key={Hrec.RecordID}>
                                                    <NavLink recordType={props.records?.RecordType} recordID={Hrec.RecordID}>
                                                        {Hrec.Fields.map(y => y.Text || y.Value).join("/")}
                                                    </NavLink>
                                                </div>
                                            })}
                                        </td>
                                    })
                                    }
                                </tr>
                            })}
                        </>
                    </tbody>
                </>
                :
                <>
                    <thead>
                        <tr>
                            {range(0, 6).map(x => <th key={x}>{GetDayName(ctx, x)}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {records && range(0, 5).map(week => {
                            return <tr key={week}>
                                {range(0, 6).map(i => {
                                    const firstDayOfMonth = new Date(fd.getFullYear(), fd.getMonth(), 1);
                                    const day = new Date(fd.getFullYear(), fd.getMonth(), 1 - firstDayOfMonth.getDay() + week * 7 + i);
                                    let recs = records?.Records.filter(x => {
                                        const fd = x.FromDate!
                                        const recday = new Date(fd)
                                        const isd = isSameDay(recday, day)
                                        return isd
                                    });
                                    recs = recs?.sortBy(x => x.FromDate)
                                    if (day.getMonth() === fd.getMonth()) {
                                        const stl: CSSProperties = { borderColor: numberColor(ctx.data.SecondaryColor), borderWidth: .5, borderStyle: "solid", verticalAlign: "top" }
                                        if (isSameDay(day, new Date())) stl.backgroundColor = "palegreen"
                                        return <td key={i} style={stl} onDoubleClick={() => {
                                            const rd = new RecordsData()
                                            rd.RecordType = props.records?.RecordType
                                            const rfp: RecordFormProps = {}
                                            rfp.record = rd
                                            rfp.presetValues = { FromDate: rightCut(day.toISOString(), "Z") }
                                            rfp.onSaved = () => props.refreshData!()
                                            ctx.openRecord!(rfp)
                                        }}>
                                            <span style={{ color: numberColor(ctx.data.SecondaryColor) }}>{day.getDate()}</span>
                                            {recs && recs.map(x => {
                                                const fd = x.FromDate!
                                                const flds = getControlProps2(x.Fields, records.Columns)
                                                const visfields = flds.filter(x => x.memberData.Visible !== false)
                                                const stl: CSSProperties = {}
                                                stl.backgroundColor = numberColor(x.BackColor?.Value)
                                                if (props.gridProps?.RecordKeys?.has(x.RecordKey!)) stl.backgroundColor = selectedColor(ctx)
                                                return <SmartDiv id="Test_Record" key={x.RecordID} data-recordid={x.RecordID} style={stl} onClick={e => {
                                                    e.preventDefault()
                                                    setTimeout(() => {
                                                        props.setSelected!([x], true, true)
                                                    }, 200)
                                                }} onDoubleClick={e => {
                                                    e.stopPropagation()
                                                    goToRecord(x)
                                                }} onContextMenu={e => {
                                                    props.setSelected!([x], true, false)
                                                    props.setMenuField!(e)
                                                }}>
                                                    <div style={{ fontWeight: "bold" }}>{new Date(fd).toLocaleTimeString()}</div>
                                                    <NavLink recordType={x.RecordType} recordID={x.RecordID}>{visfields.map(y => y.Text || y.Value).filter(x => x !== undefined && x !== null).join(" / ")}</NavLink>
                                                </SmartDiv>
                                            }
                                            )}
                                        </td>
                                    } else return <td />
                                })}
                            </tr>
                        })}
                    </tbody>
                </>
            }
        </table>
    </div>

}

