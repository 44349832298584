//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import { DialogPlus, ProgressBar, SmartButton, useEffect2, useInterval } from "./misc"
import { controlRecord2, defaultGridProps, getControlProps2, parseDate, showConfirm, numberColor, getControlProps3 } from "./globals"
import { useContext, FC, useState, useRef, useEffect } from "react"
import React from "react"
import { MachshevetClient, DataTypes, LiteRecordData, RecordsData, ColumnData, GridProps } from "./Declarations"
import { Control } from "./Control"
import { AppContext, ColumnData2, CommandInputProps, ControlProps2, handleCommandResult, MainContext } from "./styles"

export const CommandPopup: FC<CommandInputProps & { onClose: () => void }> = props => {
    const app = useContext(AppContext)!
    const ctx = useContext(MainContext)
    const [state, setState] = useState(props)
    const [files, setFiles] = useState<{ Key: string, Value: File }[]>([])
    const [addFields, setAddFields] = useState<ControlProps2[]>()
    const [toReload, setToReload] = useState(0)
    const [changedField, setChangedField] = useState<string>()
    const [changedSubField, setChangedSubField] = useState<string>()
    const [subIndex, setSubIndex] = useState<number>()
    const [dirty, setDirty] = useState(false)
    const aborter = useRef(new AbortController())

    useEffect2(async () => {
        if (toReload > 0) await reload()
    }, [toReload])

    const allflds = getControlProps2(state.data.Fields, state.data.Columns)
    //const allflds = getControlProps3(state.data.Fields, state.data.Columns)

    useEffect(() => {
        const add = allflds.filter(x => x.memberData.Name === "AdditionalFields")[0]
        if (add && add.Value) {
            const cols = add.Value as ColumnData[]
            const conts = cols.map(x => {
                const newfld = new ControlProps2()
                newfld.showTools = true
                newfld.memberData = x
                newfld.recordType = props.mainRecordType
                return newfld
            })
            setAddFields(conts)
        }


        // Find the first editable control
        const elmFocus: HTMLElement = document.querySelector('input, textarea, select')!
        elmFocus!.focus()



    }, [])


    useInterval(async () => {
        if (ctx.docActive() && !dirty) {
            await reload()
        }
    }, 9000, true)

    let flds = allflds.filter(x => !x.memberData?.IsCommand && x.memberData?.Name !== "AdditionalFields" && x.memberData?.Name !== "AdditionalValues")
    flds.forEach(x => {
        x.mainCommand = props.command.Name
    })

    if (addFields) flds = flds.concat(addFields)

    async function setFieldValue(mainField: ControlProps2, newValue: unknown, newRow?: LiteRecordData, index?: number, subField?: ControlProps2) {
        setDirty(true)
        const isfil = newValue instanceof File
        const mainname = mainField.memberData.Name
        if (mainField.mainCommand) {
            setState(prev => {
                const cols = prev.data.Columns
                const ret = flds.map(x => {
                    let newval = x.Value
                    let newdisp = x.Value
                    if (x.memberData?.Name === mainname) {
                        if (x.memberData.ListType) {
                            const v2: RecordsData = x.Value || []
                            if (newRow) v2.Records = v2.Records.concat(newRow)
                            else if (index !== undefined) {
                                const nrow = v2.Records[index!]
                                nrow.Fields = nrow.Fields.map(x => x.Key === subField?.Key ? { ...x, Value: newValue, Text: '' + newValue } : x)
                                v2.Records[index!] = nrow
                            }
                            newval = v2
                        }
                        else {//if (!isfil) {
                            newval = newValue
                            newdisp = newValue
                        }
                    }
                    if (newval && x.memberData.DataType === DataTypes.DateTime && typeof newValue === "string") newdisp = parseDate(newval)?.toString()
                    return { ...x, Value: newval, Text: newdisp } as ControlProps2
                })

                if (isfil) {
                    setFiles(prev => {
                        const ret = prev
                        ret.push({ Key: mainField.memberData.Name!, Value: newValue })
                        return ret
                    })

                }

                return { ...prev, data: { ...prev.data, Fields: ret, Columns: cols } }
            })

        }
        else {
            setAddFields(prev => {
                //const ret = prev!.map(x => x.Name === mainField.Name ? { ...x, Value: newValue, Text: '' + newValue } : x)
                const ret = prev!.map(x => x.memberData.Name === mainField.memberData.Name ? { ...x, Value: newValue, Text: '' + newValue } : x)
                return ret
            })
        }
        setChangedField(mainField.memberData.Name)
        setSubIndex(index)
        setChangedSubField(subField?.memberData.Name)

        setToReload(prev => prev + 1)
    }
    function getState() {
        const allflds = flds
        const vals2 = controlRecord2(allflds)
        if (addFields) {
            //const addvals = addFields.map(x => { return { Key: x.Name, Value: x.Value } })
            const addvals = addFields.map(x => { return { Key: x.memberData.Name, Value: x.Value } })
            vals2["AdditionalValues"] = addvals
        }
        files.forEach(x => {
            vals2[x.Key + "_File"] = x.Value
        })
        if (state.data.ExecutionID) vals2["ExecutionID"] = state.data.ExecutionID
        return vals2
    }

    const comnds = props.data.Columns.filter(x => x.IsCommand).sortBy(x => x.IsDefault)

    async function reload() {
        aborter.current.abort()
        aborter.current = new AbortController()

        const vals2 = getState()
        if (vals2.ArgsRecordType) {//hack for subscription>checknumber
            ctx.devLog!('state ' + (new Date()).getSeconds(), vals2)
            const res = await MachshevetClient.CommandData(changedField!, vals2, subIndex, changedSubField, aborter.current.signal)
            setState(prev => ({ ...prev, data: res }))
        }
    }

    return <DialogPlus {...props} title={props.command.LocalName!} footer={comnds.map(x => {
        const stl: React.CSSProperties = {}
        if (x.IsDefault) {
            stl.backgroundColor = numberColor(ctx.data.PrimaryColor)
            stl.color = "white"
        }
        return <SmartButton key={x.Name} testName={x.Name!} style={stl} onClick={async () => {
            if (x.Warning) {
                let warn = x.Warning
                warn = warn.replace("{#}", props.selectedKeys.size.toString())
                if (!showConfirm(warn)) return
            }
            const vals2 = getState()
            if (!vals2.ArgsRecordType) vals2.ArgsRecordType = props.mainRecordType
            if (!vals2.ArgsCommand) vals2.ArgsCommand = props.command.Name
            //if (!vals2.ArgsRecordKeys) vals2.ArgsRecordKeys = [...props.selectedKeys].join()
            if (!vals2.ArgsRecordKeys && props.selectedKeys) vals2.ArgsRecordKeys = [...props.selectedKeys].join()
            setDirty(false)
            const res = await MachshevetClient.InputCommand(x.Name!, vals2)
            if (res.Key === "InputData" && x.KeepOpen) {//*findmissings.find
                setState(prev => ({ ...prev, data: res.Value }))
            } else {
                const cd = x as ColumnData2
                cd.reloader = x.KeepOpen ? reload : props.command.reloader
                await handleCommandResult(res, cd, defaultGridProps(), app, ctx, props.colSetter)
                if (!x.KeepOpen) props.onClose()

            }
        }} >
            {x.LocalName}
        </SmartButton>
    })}>
        <div>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                {flds.filter(x => x.memberData && x.memberData.Visible !== false).map(column => {
                    const cp2 = column
                    cp2.mainRecordType = props.mainRecordType
                    //cp2.commandInputGetter = getState
                    cp2.editPage = true
                    cp2.gridProps = new GridProps()
                    cp2.gridProps.Command = props.command.Name// column.mainCommand
                    cp2.gridProps.ParentRecordType = column.mainRecordType
                    cp2.gridProps.Member = column.memberData.Name
                    cp2.gridProps.CommandValues = getState()
                    return <div id={"Test_Control_" + column.memberData.Name} data-editable={column.memberData.Editable} key={column.Key} style={{ opacity: column.Useful === false ? .2 : 1 }}>
                        <div>{column.memberData.LocalName}</div>
                        <Control field={{ ...cp2, onChange: async (v, f, n, i) => setFieldValue(column, v, n, i, f) }} />
                    </div>
                })}
            </div>
            <div style={{ color: "white" }}> {state.data.Progress && <ProgressBar percent={state.data.Progress} />}</div>

            <div>{state.data.Errors?.map(x => <span key={x}>{x}</span>)}</div>

        </div>
    </DialogPlus>
}