//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useContext, useEffect, useState } from "react"
import { CommandButton, EditTable, useDebuggableState } from './misc'
import { EntityPick } from './EntityPick'
import { NumberPick, PercentPick, ColorPick, SpanPick, UrlPick, PhonePick, FilePick, PdfPick, DatePick, BitPick, AudioPick, MoneyPick, MeasurePick, TextPick, ExpiryPick, HtmlPick, FormatPick, EmailPick, LongTextPick, ImagePick, PickListControl, PathPick, XmlPick, MergePick, FontPick, SvgPick, AutoCompletePick, JsonPick } from "./pickers"
import { DataTypes, FieldTypes, GridProps, SettingGroup, Transformations } from "./Declarations"
import { canEdit, inputStyle, numberColor } from "./globals"
import { ListView } from "./ListView"
import { ColumnData2, ControlProps2, MainContext } from "./styles"

export const Control: FC<{ field: ControlProps2, term?: string }> = props => {
    const ctx = useContext(MainContext)
    const [changeTime, setChangeTime] = useState(Date.now() - 10000)
    const [firstLoad, setFirstLoad] = useState(true)

    useEffect(() => {
        if (!firstLoad) setChangeTime(Date.now())//null usuually happens when originaly was undefined and now is null
        else setFirstLoad(false)
    }, [props.field.Value])

    useEffect(() => {
        setFirstLoad(true)
        setChangeTime(Date.now() - 10000)
    }, [props.field.recordID])


    const fld = { ...props.field }

    const md = fld.memberData
    const datediff = (Date.now() - changeTime) / 1000
    //let gp = new GridProps()
    //if (fld.gridProps) gp = { ...fld.gridProps }
    //gp.Member = md.SubReportID ? "" : md.Name
    //gp.ReportID = md.SubReportID
    //gp.Command = fld.mainCommand
    //gp.ParentRecordType = fld.mainRecordType
    //if (fld.commandInputGetter) gp.CommandValues = fld.commandInputGetter()
    //fld.gridProps = gp
    fld.style = { ...props.field.style, fontSize: md.FontSize }
    if (!fld.style?.color) {
        if (md.ForeColor) fld.style.color = numberColor(md.ForeColor)
    }
    if (!fld.style.fontWeight) fld.style.fontWeight = md.FontWeight
    if (fld.ErrorText) {
        fld.style.borderColor = "red"
        fld.style.outlineColor = "red"
    }

    if (fld.editPage) {
        fld.style = inputStyle(fld.style, ctx)
        if (!canEdit(fld)) fld.style.backgroundColor = "silver"
        fld.style.fontSize = "110%"
        fld.style.flexGrow = 1
        if (md.Mapped === false) fld.style.backgroundColor = "lightyellow"
    }

    if (fld.Value !== null && datediff < 5 && !canEdit(fld)) fld.style.backgroundColor = "deeppink"

    if (md.Multiline && canEdit(fld))
        return <LongTextPick {...fld} />
    else if (md.FieldType === FieldTypes.CardExpiry)
        return <ExpiryPick {...fld} />
    else if ((md.DataType === DataTypes.Boolean && md.FieldType !== FieldTypes.Gender) || md.Transformation === Transformations.HasValue)
        return <BitPick {...fld} />
    else if (md.DataType === DataTypes.DateTime)
        return <DatePick {...fld} />
    else if (md.FieldType === FieldTypes.Phone)
        return <PhonePick {...fld} />
    else if (md.DataType === DataTypes.Bytes)
        if (md.FieldType === FieldTypes.Pdf)
            return <PdfPick {...fld} />
        else if (md.FieldType === FieldTypes.Audio)
            return <AudioPick {...fld} />
        else if (md.FieldType === FieldTypes.Bitmap)
            return <ImagePick {...fld} />
        else
            return <FilePick {...fld} />

    else if ((md.ForeignTypeName || fld.RefType) && !md.ShowAll)
        return <EntityPick {...fld} />
    else if ((md.DataType === DataTypes.List || md.ShowAll) && !md.IsCommand && md.Visible !== false) {
        if (md.Editable && !md.Locked && !md.ShowAll) {
            return <EditTable {...fld} />
        } else {
            //gp.SettingGroup = SettingGroup.Columns
            //const gp2 = { ...props.field.gridProps! }
            //gp2.SettingGroup = SettingGroup.Columns
            //fld.gridProps=gp2
            //return <ListView field={fld} gridProps={gp2} showTools={false} saveState={false} />
            //return <ListView field={fld} showTools={false} saveState={false} />
            return <ListView field={fld} gridProps={fld.gridProps} showTools={false} saveState={false} />
        }
    }
    else if (md.Name === "LinkedIn")
        return <UrlPick {...fld} template="https://www.linkedin.com/in/{Handle}" />
    else if (md.FieldType === FieldTypes.Measure)
        return <MeasurePick {...fld} />
    else if (md.IsCommand)
    {
        const cd = md as ColumnData2
        cd.recordID = fld.recordID
        cd.reloader = fld.reloader
        cd.recordType = fld.recordType
        return <CommandButton {...cd} />
    }
    else if (fld.memberData.FormatPicker)
        return <FormatPick  {...fld} />
    else if (fld.memberData.FieldType === FieldTypes.Html)
        return <HtmlPick {...fld} isEditable={false} />
    else if (fld.memberData.FieldType === FieldTypes.Xml)
        return <XmlPick {...fld} />
    else if (fld.memberData.FieldType === FieldTypes.Json)
        return <JsonPick {...fld} />
    else if (fld.memberData.MergePicker)
        return <MergePick {...fld} />
    else if (fld.memberData.HasPickList && fld.memberData.Visible !== false && !fld.memberData.MergePicker) {
        if (fld.memberData.AutoComplete || fld.memberData.Name === "AddressSearch") {
            return <AutoCompletePick {...fld} />
        } else {
            return <PickListControl {...fld} />
        }
    }
    else if (fld.memberData.FieldType === FieldTypes.DrawPath)
        return <PathPick {...fld} />
    else if (fld.memberData.FieldType === FieldTypes.Color)
        return <ColorPick {...fld} />
    else if (fld.memberData.FieldType === FieldTypes.Svg)
        return <SvgPick  {...fld} />
    else if (fld.memberData.FieldType === FieldTypes.Email)
        return <EmailPick {...fld} />
    else if (fld.memberData.FieldType === FieldTypes.Url)
        return <UrlPick {...fld} />
    else if (fld.memberData.FieldType === FieldTypes.Money) {
        const nVal = +fld.Value
        const backclr = !nVal ? undefined : nVal > 0 ? 9498256 : 16761035
        fld.style!.backgroundColor = numberColor(backclr)
        return <MoneyPick {...fld} />
    }
    else if (fld.memberData.FieldType === FieldTypes.Percent)
        return <PercentPick {...fld} />
    else if (fld.memberData.FieldType === FieldTypes.Span)
        return <SpanPick {...fld} />
    else if (fld.memberData.DataType === DataTypes.Number)
        return <NumberPick {...fld} />
    else if (fld.memberData.Name === "Font")
        return <FontPick {...fld} />
    else {
        return <TextPick {...fld} />
    }
}