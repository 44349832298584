//***Copyright Notice***
//____________________________________________________
//Copyright © 2025 Machshevet (http://machshevet.com)
//All rights reserved.
//____________________________________________________
//***End Notice***

import React, { FC, useState, useRef, HTMLProps, useContext, useEffect } from 'react'
import { NavLink, useEffect2, RecordCard, Icon, Popout, SmartInput, SmartButton, useDebuggableState } from './misc'
import { ColumnData, MachshevetClient, RecordsData, SettingGroup } from './Declarations'
import { canEdit, RecordFormProps, recordValues, testAttribs } from './globals'
import { DisplayBox, PickListControl } from './pickers'
import { ListView } from './ListView'
import { ControlProps2, MainContext } from './styles'

export const EntityPick: FC<ControlProps2> = props => {
    const ctx = useContext(MainContext)!
    const [options, setOptions] = useState(props.memberData.PickList)//still needed?
    const [showResults, setshowResults] = useState(false)
    //const [gridProps, setGridProps] = useState(props.gridProps)//props.recordtype needed for input params. parentrecordtype needed for fieldsetting.pickreportid
    const [gridProps, setGridProps] = useDebuggableState(props.gridProps)//props.recordtype needed for input params. parentrecordtype needed for fieldsetting.pickreportid
    const [selection, setSelection] = useState<{ id: number, name: string }>({ id: 0, name: "" })
    const keyDownHandler = useRef<HTMLProps<HTMLInputElement>['onKeyDown']>(undefined)
    const pickRef = useRef<HTMLDivElement>(null)
    const [selectedIndex, setSelectedIndex] = useState<number>()
    const [showCard, setShowCard] = useState(false)
    const [miniRec, setMiniRec] = useState<RecordsData>()
    const [searchVals, setSearchVals] = useState<Record<string, unknown>>()
    const [showAdvanced, setShowAdvanced] = useState(false)
    const timeout = useRef<number>(0)
    //if (gridProps) gridProps.SettingGroup = SettingGroup.PickColumns

    let forntyp = props.memberData.ForeignTypeName
    let fornid = props.RefID

    if (props.RefType) forntyp = props.RefType
    if ((props.memberData.ForeignTypeName || props.RefType) && !fornid) fornid = props.Value

    useEffect2(async () => {
        if (canEdit(props)) {
            const id = parseInt(props.Value)//using fornid here causes that when defauklttype=mine, we cannot change the value in a new record
            if (id && !options) {
                const name = await MachshevetClient.GetRecordName(forntyp!, id)
                setGridProps(prev => ({ ...prev!, Term: name }))
            }
            if (!props.Value && gridProps?.Term) setGridProps(prev => ({ ...prev!, Term: "" }))
        }

        if (forntyp === "Person") {
            const flds = ["LastName", "FirstName", "Phone"]
            setSearchVals(Object.fromEntries(flds.map(key => [key, undefined])))
        }


    }, [props.Value])
    //useEffect(() => {
    //    if (gridProps) setGridProps(prev => ({ ...prev!, SettingGroup: SettingGroup.PickColumns }))
    //}, [])

    async function handleSelect(id: number, name: string) {
        setGridProps(prev => ({ ...prev!, Term: name }))
        await props.onChange!(id)
        setshowResults(false)
        props.onBlur && props.onBlur(id)
    }

    function inputKeyDown(event: KeyboardEvent | React.KeyboardEvent<HTMLInputElement>) {
        const key = event.key
        const strtidx = selectedIndex === undefined ? -1 : selectedIndex
        if (key === 'ArrowDown') {
            if (!showResults) setshowResults(true)
            else setSelectedIndex(strtidx + 1)
        }
        else if (key === 'ArrowUp') setSelectedIndex(strtidx - 1)
        else if (key === 'Enter' && selection.id > 0) handleSelect(selection.id, selection.name)
    }

    function triggerOpen() {
        timeout.current = window.setTimeout(async () => {
            if (fornid) {
                //const pr = await MachshevetClient.Preview(rectyp!, fornid)
                const pr = await MachshevetClient.Preview(forntyp!, fornid)
                if (pr && pr.Records) setMiniRec(pr)
            }
        }, 1800)
    }

    let listcontrol = props.recordType
    if (props.mainCommand) listcontrol = props.mainRecordType!
    if (!listcontrol) listcontrol = props.memberData!.ForeignTypeName!
    let dispvalue = gridProps?.Term// || props.Value //props.value is for editmode where we have the persons name in props.value
    if (props.Value && !dispvalue) dispvalue = props.Value

    const dispprops = { ...props }
    dispprops.style!.display = 'inline'
    const gp2 = { ...gridProps! }
    gp2.SettingGroup = SettingGroup.PickColumns

    return <div style={{ flexGrow: 1 }}>{
        canEdit(props) ?
            <div id='div7' ref={pickRef} >
                <div style={{ display: 'flex', alignItems: 'center', gap: ".2vmax" }}>
                    {
                        options ?
                            <PickListControl {...props} />
                            :
                            <SmartInput {...testAttribs(props)}
                                disabled={props.memberData!.Locked}
                                tabIndex={0}
                                value={dispvalue}
                                style={{ ...props.style, minWidth: dispvalue?.length + 'ch' }}
                                draggable
                                onDragStart={e => e.preventDefault()}
                                onDoubleClick={e => e.stopPropagation()}
                                onClick={() => setshowResults(!showResults)}
                                /*onBlur={() => setTimeout(() => setshowResults(false), 1000)}*/ /*interferes with right click on list*/
                                onChange={e => {
                                    const value = e.currentTarget.value
                                    if (!value || value === "") props.onChange && props.onChange(null)
                                    setshowResults(true)
                                    setGridProps(prev => ({ ...prev!, Term: value }))
                                    e.stopPropagation()
                                    return false
                                }}
                                onFocus={e => e.target.select()}
                                onKeyDown={e => {
                                    !keyDownHandler.current || keyDownHandler.current(e)
                                    if (e.keyCode === 27) setshowResults(false)
                                    inputKeyDown(e)
                                }}
                            />
                    }
                    {
                        props.showTools != false && <>
                            {searchVals && !props.Value && <Icon name="Advanced" onClick={() => setShowAdvanced(!showAdvanced)} />}
                            {showAdvanced && <div style={{ display: 'flex', alignItems: 'center' }} > {recordValues(searchVals!).map(x => <SmartInput key={x.Key} value={x.Value ? '' + x.Value : ""} placeholder={ctx.localized(x.Key)} onChange={e => {
                                const val = e.currentTarget.value
                                setSearchVals(prev => ({ ...prev, [x.Key]: val }))
                                setshowResults(true)
                                setGridProps(prev => {
                                    const colkey = x.Key + "."
                                    let newFilters = prev!.Filters
                                    if (!newFilters.some(filter => filter.ColumnKey === colkey)) {
                                        newFilters.push({ ...new ColumnData(), Name: x.Key, ColumnKey: colkey })
                                    }
                                    newFilters = newFilters.map(y => y.ColumnKey === colkey ? { ...y, FilterText: val } : y)
                                    prev!.Filters = newFilters
                                    return prev!
                                })
                                //setRefreshFlag(prev => prev + 1)
                            }} />
                            )}
                                <Icon name="Save" onClick={async () => {
                                    const newrec = await MachshevetClient.SaveRecord(forntyp!, searchVals)
                                    await props.onChange!(newrec.Records[0].RecordID)
                                    setSearchVals(prev =>
                                        Object.fromEntries(
                                            Object.keys(prev!).map(key => [key, undefined])
                                        )
                                    )
                                    setShowAdvanced(false)
                                    setshowResults(false)
                                }} />
                            </div>}
                            <NavLink recordType={forntyp} style={{ display: 'flex' }} recordID={fornid}>
                                <Icon name="Record" onMouseLeave={() => setShowCard(false)} onMouseEnter={() => {
                                    setShowCard(true)
                                    triggerOpen()
                                }} />
                            </NavLink>
                            <Icon name='Add'
                                onClick={() => {
                                    const rd = new RecordsData()
                                    rd.RecordType = props.memberData.ForeignTypeName
                                    const rfp: RecordFormProps = {}
                                    rfp.record = rd
                                    rfp.onSaved = async id => await props.onChange!(id)
                                    rfp.presetValues = { Name: gridProps?.Term }
                                    rfp.addingField = props.memberData.Name
                                    rfp.addingRecordType = props.recordType
                                    //rfp.addingRecord = props.recordValues//  props.modelGetter!()
                                    rfp.addingRecord = props.gridProps?.RecordValues
                                    ctx.openRecord!(rfp)
                                }}
                            />
                        </>
                    }
                </div>
                {
                    showResults && <Popout style={{ display: 'flex', zIndex: 2, backgroundColor: "white", flexDirection: 'column', borderStyle: 'solid', fontSize: "95%", borderWidth: 1 }}>
                        <SmartButton onClick={() => setshowResults(false)} style={{ alignSelf: "end" }}>X</SmartButton>
                        <ListView showTools={false} saveState={false} handleSelection={(id, name) => setSelection({ id, name })} gridProps={gp2} idxSelected={selectedIndex} resetSelected={e => setSelectedIndex(e)} onRowClicked={(id, name) => handleSelect(id, name)} field={props} />
                    </Popout>
                }
            </div>
            :
            <DisplayBox field={dispprops} onMouseLeave={() => setShowCard(false)} onMouseEnter={() => {
                setShowCard(true)
                triggerOpen()
            }} />
    }
        {showCard && miniRec && <Popout id="TestGlance" style={{ zIndex: 2 }}>
            <RecordCard record={miniRec.Records[0]} records={miniRec} />
        </Popout>}
    </div>
}